import React,{useState} from 'react';
import PropTypes from 'prop-types';
import FlexContainer from '../../../../components/FlexContainer';
import {getThemeColor} from '../../../../themes';
import {ReactComponent as TimerIcon} from './assets/timer.svg';
import {useAbortableEffect} from '../../../../hooks';
import {useGames, useTimers, useUsers} from '../../../../hooks/collection';
import {timer, user} from '../../../../actions';
import {useDispatch} from 'react-redux';
import {TIMER_TURN} from '../../../../utilities/game';

const Label = ({text})=>{
    return (
        <label css={`
            color:${props=>getThemeColor(props.theme.body.color,props.theme)};
        `}>{text}
        </label>
    )
}

const Info = ({text,isTimerOn = false,onTimerEnd,timerType = '',timerId = '',timerDuration = 0})=>{
    const [countDown,setCountDown] = useState(null);
    const [serverTime,setServerTime] = useState(null);
    const [localTime,setLocalTime] = useState(null);
    const dispatch = useDispatch();
    const {data:dataUser} = useUsers(user.get());
    const {data:dataGame} = useGames(dataUser ? dataUser.currentGame : 0);
    
    useTimers(timerId ? timerId : 0,{
        onLoaded:(resp)=>{
            const {turn, currentTime} = resp.data[timerId] || {};
            if(turn && currentTime){
                const start = turn.toDate().getTime()/1000;
                const end = currentTime.toDate().getTime()/1000;
                setServerTime({
                    start,
                    end,
                });
            }else{
                setServerTime(null);
            }
        }
    });

    useAbortableEffect((status)=>{
        if(!status.aborted){
            if(isTimerOn && serverTime){
                const { start, end } = serverTime;
                const diff = timerDuration - (end - start);
                const localDiff = timerDuration - (localTime - start);
                const checkpointThreshold = localDiff < 2 || localDiff > TIMER_TURN;
                
                if(diff < 0){
                    onTimerEnd && onTimerEnd();
                }else{
                    setCountDown(localDiff);
                    if(checkpointThreshold) {
                        dispatch(
                            timer.create({
                                params: {
                                    id:dataGame.id,
                                    key:'currentTime',
                                }
                            })
                        );
                    }
                }
            }
        }
    },[localTime]);
    
    useAbortableEffect(() => {
        const timer = setInterval(() => {
            setLocalTime((new Date()).getTime()/1000)
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div isTimerOn={isTimerOn} countDown={countDown} css={
            `margin:${props=>!(props.isTimerOn && (props.countDown > 0)) ? "16px 0px" : "0px"};`
        }>
            <FlexContainer height="auto" flexDirection="row" justifyContent="center">
                <Label text={text}/>

                {isTimerOn && (countDown > 0) &&
                <>
                <TimerIcon css={`
                    width:10px;
                    height:10x;
                    margin-left:12px;
                    margin-right:6px;
                    & path{
                        fill:${props=>getThemeColor(props.theme.body.color,props.theme)}
                    }
                `}/>
                <Label text={countDown <= 0 ? '0' : countDown.toFixed(0)}/>
                </>
                }
            </FlexContainer>
        </div>
    );
}

Info.propTypes = {
    text:PropTypes.string.isRequired
}

Info.defaultProps = {
}

export default Info;
