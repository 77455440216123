import shuffle from 'shuffle-array';

const SUITS = ['C', 'D','H','S'];
const RANKS = ['A', '2','3','4','5','6','7','8','9','10','J','Q','K'];
const SCORES = {
    "A":1,
    "J":10,
    "Q":10,
    "K":10,
    "1":1,
    "2":2,
    "3":3,
    "4":4,
    "5":5,
    "6":6,
    "7":7,
    "8":8,
    "9":9,
    "10":10,
}
const PENALTY = 40;
const MIN_SCORE = 200;
const MAX_ROUND_SCORE = 40;
export const TIMER_TURN = 60;
export const TIMER_INTERVAL = 0;
export const TIMER_NEXT_ROUND = 8;
export const TIMER_TO_LOBBY = 0;
export const TIMER_ANIMATION = .5;
export const TIMER_SINGLE_CARD_ANIMATION = .15;
export const INITIAL_CARD_PER_PLAYER = 7;
export const SINGLE_CARD_WIDTH = 104;

export const STATUS = {
    NONE: '',
    INITIAL: 'initial',
    START: 'start',
    DROPPED_SAME: 'dropped_same',
    DROPPED_NOT_SAME: 'dropped_not_same',
    DROPPED_CHAINABLE: 'dropped_chainable',
    PICKED: 'picked',
    PICK_FROM_OPEN_DECK: 'pick_from_open_deck',
    BEFORE_COMMIT_MOVE: 'before_commit_move',
    COMMIT_MOVE: 'commit_move',
    SHOW: 'show',
    ANNOUNCE: 'announce',
    TIMEOUT: 'timeout',
    SWITCH_TURN: 'switch_turn',
    END_ROUND: 'end_round',
    END_GAME: 'end_game',
}

/**
 * @returns {Array} - [s2,h1,...card]
 */
const generateDeck = () => {
    const decks = [];
    for(let i=0;i<SUITS.length;i++){
        for(let j=0;j<RANKS.length;j++){
            decks.push(`${SUITS[i].toLowerCase()}${RANKS[j].toLowerCase()}`);
        }
    }

    shuffle(decks);
    return decks;
}


/**
 * Randomly pick cards
 * 
 * @param {Array} decks - [s2,h1,...card] - By Reference
 * @param {number} total
 * @returns {string} - s2
 */
const pickCardByRandom = (decks,total = 1) => {
    let picked = shuffle.pick(decks,{'picks': total});
    if(typeof picked === 'string'){
        picked = [picked];
    }

    let removed = 0;

    for( let i = 0; i < decks.length; i++){ 
        if ( picked.includes(decks[i])) { 
            decks.splice(i, 1); 
            i--; 
            removed++;
        }

        if(removed === picked.length){
            break;
        }
    }

    return picked;
}


/**
 * Pick card by index
 * 
 * @param {Array} arrOfCards - [s2,h1,...card] - By Reference
 * @param {number} index
 * @param {boolean} removed - should remove the picked card
 * @return {string} - e.g.: s2
 */
export const pickCardByIndex = (arrOfCards,index,removed = false) => {
    let picked = '';
    for(let i = 0;i < arrOfCards.length;i++){
        if(i === index){
            picked = arrOfCards[i];
            if(removed){
                arrOfCards.splice(i,1);
                i--;
            }
            break;
        }
    }

    return picked;
}

/**
 * Pick card by value
 * 
 * @param {Array} arrOfCards - [s2,h1,...card] - By Reference
 * @param {string} value
 * @param {boolean} removed - should remove the picked card
 * @return {string} - e.g.: s2
 */
export const pickCardByValue = (arrOfCards,value,removed = false) => {
    let picked = '';
    for(let i=0;i<arrOfCards.length;i++){
        if(arrOfCards[i] === value){
            picked = arrOfCards[i];
            if(removed){
                arrOfCards.splice(i,1);
                i--;
            }
            break;
        }
    }

    return picked;
}

export const pickCardFromClosed = (decks) => {
    return pickCardByIndex(decks.closed,decks.closed.length-1,true);
}

const getInitialStateDecks = (players) => {
    let decks;
    if(players.length>4){
        decks = [...generateDeck(),...generateDeck()];
    }else{
        decks = [...generateDeck()];
    }

    shuffle(decks);

    const holding = {};
    if(players && players.length){
        for(let player of players){
            holding[player.id] = pickCardByRandom(decks,7);
        }
    }

    const wildcard = pickCardByRandom(decks,1)[0];
    const opened = pickCardByRandom(decks,1);

    return {
        'opened': opened,
        'closed': decks,
        'wildcard': wildcard,
        'current': [], //dropped
        'picked': [],
        'holding': holding,
    }
}

export const map2Json = (card) => {
    if(card){
        if(typeof card === 'string'){
            const rank = card.slice(1,card.length).toUpperCase();
            const suit = card[0].toUpperCase();
        
            return {
                type: suit,
                value: rank,
            }
        }else{
            let newCards = [];
            for(let i=0;i<card.length;i++){
                newCards.push(map2Json(card[i]));
            }
    
            return newCards;
        }
    }
    
    return [];
}

//clone object
export const cloneObj = (obj) => {
    return obj ? JSON.parse(JSON.stringify(obj)) : null;
}

/**comparison */
//where b = string
export const isSameCard = (a,b) => {
    let newData = [];
    if(Array.isArray(a)){
        newData = a.map((val) => {
            return map2Json(val);
        });
    }else if(typeof a === 'string'){
        newData.push(map2Json(a));
    }
    
    let mapB = map2Json(b);
    return newData.filter((val) => {
        return val.value === mapB.value;
    }).length;
}

export const contains = (yourCard,compareTo) => {
    for(let i=0;i<yourCard.length;i++){
        for(let j=0;j<compareTo.length;j++){
            if(map2Json(yourCard[i]).value === map2Json(compareTo[j]).value){
                return true;
            }
        }
    }

    return false;
}

export const getHoldingByUser = (holding,userId) => {
    return holding[userId] ? cloneObj(holding[userId]) : [];
}

export const genIdDom = (val,index) => {
    return `${index}-${val.type.toLowerCase()}${val.value.toLowerCase()}`;
}

export const extIdCard = (id) => {
    return id.split("-").pop();
}

export const init = (user,game,game_users,game_rs,game_rrs,deck,locale) => {
    !user && (user = {});
    !game && (game = {});
    !game_users && (game_users = []);
    !game_rs && (game_rs = {});
    !game_rrs && (game_rrs = {});
    !deck && (deck = {});
    !locale && (locale = {});

    const isRole = role => {
        return game_users.filter((game_user) => {
            return game_user ? game_user.id === user.id && game_user[role] : false;
        }).length;
    }

    const run = () => {
        return {
            params: {
                game,
                decks: getInitialStateDecks(game_users),
            },
        }
    }
    
    const isTurn = userid => {
        return game.currentTurn === (userid ? userid : user.id);
    }

    const getScore = ({userid, rollback = false, raw = false}) => {
        if(!deck.holding){
            return 0;
        }

        userid = (userid ? userid : user.id);
        let cards = cloneObj(deck.holding[userid]);
    
        if(rollback && game.status === STATUS.TIMEOUT){
            if(deck.current && deck.current.length){
                for(let i=0;i<deck.current.length;i++){
                    cards.push(deck.current[i]);
                }
            }

            if(deck.picked && deck.picked.length){
                for(let i=0;i<deck.picked.length;i++){
                    pickCardByValue(cards,deck.picked[i].value,true);
                }
            }
        }

        let wildcard = map2Json(deck.wildcard);
        cards = map2Json(cards);
        let total = 0;
        for(let i=0;i<cards.length;i++){
            if(cards[i].value !== wildcard.value){
                total+= SCORES[cards[i].value];
            }
        }
    
        return raw ? total : Math.min(total, MAX_ROUND_SCORE);
    }

    const getPlayers = (type = 'exclude_me') => {
        return game_users.filter((val) => {
            if(type === 'exclude_me'){
                return val.id !== user.id;
            }else if(type === 'all'){
                return true;
            }
        }).map((val) => {
            return {...val,_turn: isTurn(val.id)};
        })
    }

    const getName = (userid) => {
        userid = (userid ? userid : user.id);
        for(let i=0;i<game_users.length;i++){
            if(game_users[i].id === userid){
                return game_users[i].name;
            }
        }

        return '';
    }

    const getLeaderBoard = () => {
        const gameScores = {
            total: Object.keys(game_rs).length,
            data: [],
        }
    
        if(Object.keys(game.gameScores).length){
            gameScores.data = cloneObj(game_users).map((val) => {
                let point = game.gameScores[val.id];
                if(!point){
                    point = 0;
                }
                val.point = point;
                val.details = [];
                return val;
            });
    
            for(let x in game_rs){
                for(let y = 0;y < gameScores.data.length;y++){
                    if(game_rs.hasOwnProperty(x)) {
                        gameScores.data[y].details.push(game_rs[x][gameScores.data[y].id]);
                    }
                }
            }
    
            gameScores.data.sort((a,b)=>a.point-b.point);
        }
    
        return {
            gameScores,
        }
    }

    const getInfoMessage = (status = '') => {
        let switchCase = (status ? status : game.status);
        switch(switchCase){
            case STATUS.START:
                if(isTurn()){
                    return locale.start;
                }else{
                    let name = getName(game.currentTurn);
                    return name ? locale.opStart.replace(/{name}/g,name) : '';
                }
            case STATUS.TIMEOUT:
                return locale.timeout.replace(/{name}/g,getName(game.currentTurn));
            case STATUS.SHOW:
            case STATUS.ANNOUNCE:
            case STATUS.END_ROUND:
                return locale.endRound;
            case STATUS.END_GAME:
                return locale.endGame.replace(/{timer}/g,TIMER_TO_LOBBY);
            case STATUS.DROPPED_NOT_SAME:
                return isTurn() ? locale.droppedNotSame : getInfoMessage(STATUS.START);
            case STATUS.DROPPED_CHAINABLE:
                return isTurn() ? locale.droppedStill: getInfoMessage(STATUS.START);
            case STATUS.DROPPED_SAME:
                return isTurn() ? locale.droppedSame : getInfoMessage(STATUS.START);
            case STATUS.PICKED:
            case STATUS.PICK_FROM_OPEN_DECK:
                return isTurn() ? locale.picked : getInfoMessage(STATUS.START);
            case STATUS.BEFORE_COMMIT_MOVE:
            case STATUS.COMMIT_MOVE:
            case STATUS.SWITCH_TURN:
                return locale.switchTurn;
            case STATUS.NONE:
            case STATUS.INITIAL:
            default:
                return locale.initial;
        }
    }

    const getHolding = (userid) => {
        if(!deck.holding){
            return;
        }

        userid = (userid ? userid : user.id);
        return cloneObj(deck.holding[userid]);
    }

    const moveFromHost2Table = (indexCard,userid) => {
        userid = (userid ? userid : user.id);
        let cloneDecks = cloneObj(deck);
        let picked = '';
        for(let key in cloneDecks.holding){
            if(key === userid){
                picked = pickCardByIndex(cloneDecks.holding[key],indexCard,true);
                break;
            }
        }
    
        cloneDecks.current.push(picked);
        const tmpID = cloneDecks.id;
        delete cloneDecks.id;

        let status;
        let isSame = isSameCard(cloneDecks.current,cloneDecks.opened[cloneDecks.opened.length-1]);
        let isStill = contains(getHoldingByUser(cloneDecks.holding,user.id),cloneDecks.current);
        if(isStill){
            status = STATUS.DROPPED_CHAINABLE;
        }else if(isSame){
            status = STATUS.DROPPED_SAME;
        }else{
            status = STATUS.DROPPED_NOT_SAME;
        }

        return {
            same: isSame,
            still: isStill,
            changes: {
                id: tmpID,
                fields: cloneDecks,
            },
            status,
        }
    }
    
    const pickFromTable = (insertToIndex,from,userid) => {
        userid = (userid ? userid : user.id);
        
        const pickedFromClosed = from === 'closed';
        let cloneDecks = cloneObj(deck);
        const valueOfCard = pickedFromClosed
            ? pickCardFromClosed(cloneDecks)
            : extIdCard(from);

        for(let key in cloneDecks.holding){
            if(key === userid){
                cloneDecks.holding[key].splice( insertToIndex, 0, valueOfCard );
                pickCardByIndex(cloneDecks.opened,cloneDecks.opened.length-1,(from !== 'close'));
                break;
            }
        }

        cloneDecks.picked.push({"value": valueOfCard,"from":(from === "close" ? "closed" : "opened")});
        const tmpID = cloneDecks.id;
        delete cloneDecks.id;

        return {
            changes: {
                id: tmpID,
                fields: cloneDecks,
            },
            status: pickedFromClosed 
                ? STATUS.PICKED
                : STATUS.PICK_FROM_OPEN_DECK,
        }
    }

    const beforeCommitMove = () => {
        let cloneDecks = cloneObj(deck);
        for(let i=0;i<cloneDecks.current.length;i++){
            cloneDecks.opened.push(cloneDecks.current[i]);
        }
    
        cloneDecks.current = [];
        cloneDecks.picked = [];
    
        const tmpID = cloneDecks.id;
        delete cloneDecks.id;

        return {
            changes: {
                id: tmpID,
                fields: cloneDecks,
            },
        }
    }

    const commitMoveCard = () => {
        let cloneDecks = cloneObj(deck);
        let tmpLastOne = pickCardByIndex(cloneDecks.opened,cloneDecks.opened.length-1,true);
        cloneDecks.closed = [...cloneDecks.opened,...cloneDecks.closed];
        cloneDecks.opened = [tmpLastOne];
        
        shuffle(cloneDecks.closed);
    
        const tmpID = cloneDecks.id;
        delete cloneDecks.id;

        return {
            changes: {
                id: tmpID,
                fields: cloneDecks,
            },
        }
    }

    const shouldCommitMoveCard = () => {
        return deck.closed.length < 1 && deck.opened.length > 1;
    }

    const nextTurn = (single = false) => {
        let users = game_users.filter((val) => {
            return !val.spectator;
        });
        
        let nextUserId = '';
        for(let i=0;i<users.length;i++){
            if(users[i].id === game.currentTurn){
                if(i === users.length-1){
                    nextUserId = users[0].id;
                }else{
                    nextUserId = users[i+1].id;
                }
                break;
            }
        }

        if(single){
            return nextUserId;
        }
    
        return {
            changes: {
                id: game.id,
                fields: {
                    currentTurn: nextUserId,
                    status: STATUS.START,
                },
            },
        }
    }

    const getMinScores = (scores) => {
        let min = -1;
        let winner = '';
        for(let userKey in scores){
            if(
              !isRole('spectator',userKey) 
              && scores.hasOwnProperty(userKey)
            ){
                if(min === -1){
                    min = scores[userKey];
                    winner = userKey;
                }else{
                    if(scores[userKey] < min){
                        min = scores[userKey];
                        winner = userKey;
                    }
                }
            }
        }
    
        return winner;
    }

    const show = () => {
        let roundScores = {};
        let rawRoundScores = {};
        let gameScores = cloneObj(game.gameScores);
    
        const players = getPlayers('all');
        const rawScores = players.reduce((acc, player) => {
            acc.push(getScore({userid: player.id, raw: true}));
            return acc;
        }, []);
        for(let i = 0;i < players.length;i++){
            const score = getScore({userid: players[i].id, rollback: true});
            const rawScore = getScore({userid: players[i].id, rollback: true, raw: true});
            const min = Math.min(...rawScores);
            const withMinScores = rawScores.filter(item => item === min);
            if(isTurn(players[i].id) 
              && (
                (withMinScores.length > 1 
                && rawScore === min)
                || rawScore > min
              )
            ) {
                roundScores[players[i].id] = PENALTY;
                rawRoundScores[players[i].id] = PENALTY;
            } else {
                roundScores[players[i].id] = score;
                rawRoundScores[players[i].id] = rawScore
            }
            if(!gameScores[players[i].id]){
                gameScores[players[i].id] = 0;
            }
        }
    
        const winner = getMinScores(rawRoundScores);
        for(let i = 0;i < players.length;i++){
            if(!players[i].spectator){
                if(players[i].id === winner){
                    roundScores[players[i].id] = 0;
                    rawRoundScores[players[i].id] = 0;
                }else{
                    gameScores[players[i].id] += roundScores[players[i].id];
                }
            }
        }

        return {
            roundScores: {
                roundid: game.round,
                gameid: game.id,
                scores: roundScores,
                rawScores: rawRoundScores
            },
            gameScores: {
                id: game.id,
                fields: {
                    gameScores: gameScores,
                    status: STATUS.ANNOUNCE,
                },
            },
        }
    }

    const isFinish = (kicked = []) => {
        let users = getPlayers('all');
        let totalSpectator = 0;
        for(let x in game_users){
            if(game_users.hasOwnProperty(x) && game_users[x].spectator || kicked.includes(game_users[x].id)){
                totalSpectator++;
            }
        }
    
        return users.length-totalSpectator <= 1;
    }

    const getWinner = (kicked = []) => {
        let cloneScores = cloneObj(isFinish(kicked) ? game.gameScores : game_rrs[game.round]);
        return getMinScores(cloneScores);
    }

    const getKickedPlayers = () => {
        const kicked = [];
    
        for(let userKey in game.gameScores){
            if(
                !isRole('spectator',userKey)
                && game.gameScores.hasOwnProperty(userKey)
            ){
                if(game.gameScores[userKey] >= MIN_SCORE){
                    kicked.push(userKey);
                }
            }
        }
    
        return kicked;
    }
    
    const getResult = ({ roundScores, rawRoundScores }) => {
        let subtitle = locale.show.replace(/{name}/g,getName(game.currentTurn));
        let data = getPlayers('all');
        let kicked = getKickedPlayers();
        let isEnd = isFinish(kicked);
        let winner = getWinner(kicked);
        let isWin = winner === user.id;
        let isKicked = kicked.includes(user.id);
        let title;
        const resultRoundScores = roundScores === null ? game_rs : roundScores;
        const resultRawRoundScores = rawRoundScores === null ? game_rrs : rawRoundScores;
        if(isEnd){
            title  = isWin ? locale.resultWin : locale.resultLose;
        }else{
            title = locale.resultEndRound.replace(/{round}/g,game.round);
        }
        
        data = data.map((val) => {
            const isWinner = (winner === val.id);
            val.point = isEnd 
              ? game.gameScores[val.id] 
              : (resultRoundScores[game.round] || {})[val.id];
            val.rawPoint = isEnd
              ? game.gameScores[val.id]
              : (resultRawRoundScores[game.round] || {})[val.id];
            val.isWinner = isWinner;
            val.isHighlight = (val.id === user.id);
            val.isKicked = kicked.includes(val.id);
            return val;
        });

        data.sort((a, b) => {
            return a.point - b.point
        });

        return {
            title,
            subtitle,
            roundScores: data,
            isWin,
            isEnd,
            isKicked,
            nextRound: game.round+1,
            kicked,
            winner,
            countdown: TIMER_NEXT_ROUND,
        }
    }

    const getDelay = (userid) => {
        userid = (userid ? userid : user.id);
        if(userid === deck.currentTurn){
            return 0;
        }

        for(let index in game_users){
            if(game_users.hasOwnProperty(index) && userid === game_users[index].id){
                return ((parseInt(index)+1)*TIMER_INTERVAL);
            }
        }
    
        return TIMER_INTERVAL;
    }

    const isTimerOn = () => {
        return [
            STATUS.START,
            STATUS.DROPPED_SAME,
            STATUS.DROPPED_NOT_SAME,
            STATUS.DROPPED_CHAINABLE,
            STATUS.PICKED,
        ].includes(game.status);
    }

    const isEnded = () => {
        return [STATUS.ANNOUNCE,STATUS.END_ROUND,STATUS.END_GAME];
    }

    const getTotalClosedCards = () => {
        if(!deck.closed){
            return 0;
        }

        switch(deck.closed.length){
            case 2:
                return 2;
            case 1:
                return 1;
            case 0:
                return 0;
            default:
                return 3;
        }
    }

    return{
        Game: game,
        User: user,
        Deck: deck,
        run,
        isRole,
        isTurn,
        getScore,
        getPlayers,
        getName,
        getLeaderBoard,
        getInfoMessage,
        getHolding,
        moveFromHost2Table,
        pickFromTable,
        beforeCommitMove,
        commitMoveCard,
        shouldCommitMoveCard,
        nextTurn,
        show,
        getResult,
        isFinish,
        getKickedPlayers,
        getWinner,
        getDelay,
        isTimerOn,
        getTotalClosedCards,
        isEnded,
    }
}

export const areYouHost = (gameUsers,userId) => {
    return gameUsers ? gameUsers.filter((val) => {
        return val ? val.id === userId && val.host : false;
    }).length : false;
}

export const getHostName = (game) => {
    for (let key in game) {
        if(game.hasOwnProperty(key) && game[key].host){
            return game[key].name;
        }
    }
}

export const getLobbySidebar = (game,gameUsers) => {
    if(game){
        const roomID = game.roomCode;
        const users =  gameUsers;
        let players = [];
        let host = {};
        for (let key in users) {
            if(users.hasOwnProperty(key)) {
                if(users[key].host){
                    host = users[key];
                }
                players.push({
                    ...users[key],
                    isReady:!users[key].spectator,
                });
            }
        }

        return {
            players,
            roomID,
            host,
        }
    }

    return game;
}
