import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
    border-radius:${props => props.borderRadius};
    background-color:${props => props.backgroundColor ? props.backgroundColor : props.theme.tertiary};
    max-width:${props=>props.maxWidth};
    min-width:${props=>props.minWidth};
    padding:${props=>props.padding};
`;

export default (_props)=>{

    /*eslint-disable no-undef*/
    const props = {
        children,
        borderRadius = '1.5em',
        maxWidth = '240px',
        minWidth = '240px',
        padding = '0',
        backgroundColor = '',
        style
    } = _props;
    /*eslint-enable no-undef*/
    
    return (
        <Div {...props}>
            {props.children}
        </Div>
    )
}
