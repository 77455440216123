import {
    APP_SET_LANGUAGE,
    APP_SET_THEME,
    APP_SET_MODAL_OPENED,
    APP_SET_FAILURE,
    APP_SET_REQUEST,
    APP_SET_SUCCESS,
    APP_SET_CAPTCHA,
} from './constant';

export const setLanguage = (payload) => {
    return {
        type: APP_SET_LANGUAGE,
        payload,
    }
}

export const setTheme = (payload) => {
    return {
        type: APP_SET_THEME,
        payload,
    }
}

export const setModalOpened = (payload) => {
    let params;
    if(!payload) {
        params = {
            modalOpened: false,
            modalId: false,
        };
    } else if (typeof payload === 'string'){
        params = {
            modalOpened: payload,
            modalId: 'game',
        };
    } else {
        params = payload;
    }
    return {
        type: APP_SET_MODAL_OPENED,
        payload: params,
    }
}

export const setRequest = (payload = true) => {
    return {
        type: APP_SET_REQUEST,
        payload,
    }
}

export const setSuccess = (payload) => {
    return {
        type: APP_SET_SUCCESS,
        payload,
    }
}

export const setFailure = (payload) => {
    return {
        type: APP_SET_FAILURE,
        payload,
    }
}

export const setCaptcha = (payload) => {
    return {
        type: APP_SET_CAPTCHA,
        payload,
    }
}
