import React from 'react';
import { useDispatch } from 'react-redux';
import FlexContainer from '../../../components/FlexContainer';
import Button from '../../../components/Button';
import {setModalOpened} from '../../../actions/app';
import {useLocale} from '../../../hooks';

const Action = props => {
    const {
        countdown,
        nextRound,
        onNextRound,
        onSpectate,
        onMainMenu,
        isEnd,
        isKicked,
        isForfeited,
    } = props;
    const dispatch = useDispatch();
    const closeModal = () => dispatch(setModalOpened(false));
    const locale = useLocale('game');
    
    const doNextRound = () => {
        onNextRound && onNextRound();
        closeModal();
    }

    const doForfeit = () => {
        closeModal();
        dispatch(setModalOpened('forfeit'));
    }
    const doSpectate = () => {
        onSpectate && onSpectate();
        closeModal();
    }
    const doMainMenu = () => {
        onMainMenu && onMainMenu();
        closeModal();
    }

    let child = '';
    if(isKicked||isForfeited){
        child = (
            <>
                <Button onClick={doSpectate}>{locale.resultAction.spectate}</Button>
                <Button onClick={doMainMenu}>{locale.resultAction.main} { isForfeited ? null : `(${countdown < 0 ? 0 : countdown})` }</Button>
            </>
        )
    }else if(isEnd){
        child = (
            <>
                <Button onClick={doMainMenu}>{locale.resultAction.main} ({countdown < 0 ? 0 : countdown})</Button>
            </>
        )
    }else{
        child = (
            <>
                <Button onClick={doForfeit}>{locale.resultAction.fortfeit}</Button>
                <Button onClick={doNextRound}>{locale.resultAction.nextRound} {nextRound} ({countdown < 0 ? 0 : countdown})</Button>
            </>
        )
    }

    return (
        <div css={`margin:16px;`}>
            <FlexContainer height="auto" flexDirection="row">
                {child}
            </FlexContainer>
        </div>
    )
}

export default Action;
