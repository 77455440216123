export const APP_SET_LANGUAGE = 'APP_SET_LANGUAGE';
export const APP_SET_THEME = 'APP_SET_THEME';
export const APP_SET_MODAL_OPENED = 'APP_SET_MODAL_OPENED';
export const APP_SET_REQUEST = 'APP_SET_REQUEST';
export const APP_SET_SUCCESS = 'APP_SET_SUCCESS';
export const APP_SET_FAILURE = 'APP_SET_FAILURE';
export const APP_SET_CAPTCHA = 'APP_SET_CAPTCHA';

export const USER_SET_REQUEST = 'USER_SET_REQUEST';
export const USER_SET_SUCCESS = 'USER_SET_SUCCESS';
export const USER_SET_FAILURE = 'USER_SET_FAILURE';

export const LOBBY_TOGGLE_RANDOM_MATCH = 'LOBBY_TOGGLE_RANDOM_MATCH';

export const DECK_SET_REQUEST = 'DECK_SET_REQUEST';
export const DECK_SET_SUCCESS = 'DECK_SET_SUCCESS';
export const DECK_SET_FAILURE = 'DECK_SET_FAILURE';

export const GAME_SET_REQUEST = 'GAME_SET_REQUEST';
export const GAME_SET_SUCCESS = 'GAME_SET_SUCCESS';
export const GAME_SET_FAILURE = 'GAME_SET_FAILURE';
