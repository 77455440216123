import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
    margin:${props=>props.margin};
    text-align:${props=>props.textAlign};
`;

const Label = styled.label`
    margin:${props=>props.margin};
    display:${props=>props.display};
`;

export default (_props)=>{

    /*eslint-disable no-undef*/
    const props = {
        children,
        container={},
        label={}
    } = _props;

    if(typeof props.label === 'string'){
        props.label = {
            content:props.label
        }
    }

    let labelProps;
    {
        const _labelProps = {content='',margin='0 0 0.25em 0',display='block'} = props.label;
        labelProps = _labelProps;
    }
    
    let containerProps;
    {
        const _containerProps = {margin='0.5em',textAlign = 'left'} = props.container;
        containerProps = _containerProps;
    }
    /*eslint-enable no-undef*/
    
    return (
        <Div {...containerProps}>
            {labelProps.content && <Label {...labelProps}>{labelProps.content}</Label>}
            {props.children}
        </Div>
    )
}