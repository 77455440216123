import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from '../../hooks';
import { setModalOpened } from '../../actions/app';
import FlexContainer from '../../components/FlexContainer';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import Ads from '../../components/Ads';

const LobbyMain = ({
      isHost,
      onStart,
      currentHostName,
    }) => {
    const locale = useLocale();
    const dispatch = useDispatch();
    const confirmLeave = () => {
      dispatch(
        setModalOpened({
          modalOpened: 'confirmLeave',
          modalId: 'lobby',
        })
      )
    };
    
    return (
        <FlexContainer margin="2em" justifyContent="space-around">
            <div>
                <Avatar size={6}/>
                <label style={{textAlign:"center",margin:"1em 0 0 0",fontWeight:"bold",display:"block"}}>{currentHostName}</label>
            </div>
            {
              isHost 
                ? (
                  <div>
                    <Button width={"7em"} onClick={onStart}>{locale.lobby.start}</Button>
                    <Button width={"7em"} onClick={confirmLeave}>{locale.lobby.leave}</Button>
                  </div>
                )
                : <Button width={"7em"} onClick={confirmLeave}>{locale.lobby.leave}</Button>
            }
            <Ads/>
        </FlexContainer>
    );
}

export default LobbyMain;
