import {setFailure,setSuccess,setRequest} from './app';

export const create = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {id,key='turn',init} = params;
      const docRef = db.collection("timers").doc(id);
      const timestamp = db.FieldValue.serverTimestamp();
      const fields = init ? {[key]:timestamp, currentTime:timestamp} : {[key]:timestamp};
      await docRef.set(fields, {merge: true});

      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}


export const remove = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
    indicator && dispatch(setRequest());
    const db = getFirestore();
    try{
        const {id,key} = params;
        const docRef = db.collection("timers").doc(id);
        await docRef.delete();
        
        dispatch(setSuccess());
    }catch(err){
        dispatch(setFailure(err.message));
        throw err;
    }
}
                    
