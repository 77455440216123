import React,{useState} from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { user } from '../actions';
import { Generator } from '../utilities';
import { useUsers } from '../hooks/collection';
import { 
    useLocale, 
    useTheme, 
    useIsFsRequesting, 
    useAbortableEffect, 
    useCaptcha,
} from '../hooks';
import FlexContainer from '../components/FlexContainer';
import AppHeader from '../components/AppHeader';
import Button from '../components/Button';
import Container from '../components/Container';
import Avatar from '../components/Avatar';
import Input from '../components/Input';
import Captcha from '../components/Captcha';
import Field from '../components/Field';
import Loader from '../components/Loader';

let CaptchaGenerator = Generator.captcha({
    alert:alert.bind(window)
});

export default (props)=>{
    const {history,match} = props;
    const {
        data:dataUser,
        empty:isDataUserEmpty,
    } = useUsers(user.get(),{
        onLoaded:(resp)=>{
            if(!resp.empty){
                if(resp.data.currentGame === match.params.id){
                    history.replace("/lobby");
                }
            }
        }
    });

    useAbortableEffect((status)=>{
        if(!status.aborted){
            if(dataUser){
                setUsername(dataUser.name);
            }
        }
    },[dataUser]);

    const locale = useLocale();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { captcha: captchaValue } = useCaptcha();
    const [username,setUsername] = useState('');
    const [captcha,setCaptcha] = useState('');
    const [room,setRoom] = useState(match.params.id ? match.params.id : '');
    const loading = useIsFsRequesting();
    
    const toast = (content, type) => addToast(content, {
        appearance: type,
        autoDismiss: true,
    });

    const validateAll = () => {
        const usernameValid = dataUser || (username && username.trim().length);
        const captchaValid = captcha && captcha.trim().length;
        const captchaMatched = captchaValue.toLowerCase() === captcha.toLowerCase();
        const roomExists = room && room.trim().length;
        if(!usernameValid) toast(`${locale.create.name} ${locale.create.required}`, 'error');
        if(!captchaValid) toast(`${locale.create.captcha} ${locale.create.required}`, 'error');
        if(captchaValid && !captchaMatched) toast(`${locale.create.captcha} ${locale.create.wrong}`, 'error');
        if(!roomExists) toast(`${locale.join.room} ${locale.create.required}`, 'error');
        return usernameValid && captchaValid && captchaMatched;
    };
    
    const onJoin = ()=>{
        if( validateAll() ) {
            dispatch(user.joinRoom({
                params:{
                    roomId:room,
                    oldRoomId:dataUser.currentGame,
                    user:isDataUserEmpty ? false : dataUser,
                    username:username,
                    theme:theme.theme,
                    language:locale.locale
                }
            })).then(() => {
                history.replace("/lobby");
            }).catch( err => {
                alert(err.message);
            })
        }
    }

    return (
        <Loader loading={loading}>
            <FlexContainer height={95}>
                <AppHeader title={locale.app.title} subtitle={locale.app.subtitle}/>

                <Container maxWidth="360px" minWidth="360px">
                    <FlexContainer height="auto" alignItems="left" padding="1.5rem 3rem">
                       <Field label={locale.create.avatar}>
                           <Avatar size={6}/>
                       </Field>
                       <Field label={locale.create.name}>
                           <Input type="text" name="name" value={username} onChange={e=>setUsername(e.target.value)}/>
                       </Field>
                        <Field label={locale.join.room}>
                            <Input type="text" name="room" value={room} onChange={e=>setRoom(e.target.value)}/>
                        </Field>
                        <Field label={locale.create.captcha}>
                            <Captcha type="text" name="captcha" value={captcha} onChange={e=>setCaptcha(e.target.value)} captcha={CaptchaGenerator}/>
                        </Field>
                        <Field container={{textAlign:'center',margin:'1.5em 0 0 0'}}>
                            <Button backgroundColor="secondary" width="7em" alignSelf="center" onClick={onJoin}>{locale.join.submit}</Button>
                        </Field>
                    </FlexContainer>
                </Container>
            </FlexContainer>
        </Loader>
    );
}
