import React from 'react';
import ReactLoading from 'react-loading';
import FlexContainer from '../FlexContainer';
import Button from '../Button';
import { SpinnerComponent } from 'react-element-spinner';

export default ({ type = 'cubes', color = '#ffffff',loading = false,children,error='',onRetry,overlay=true}) => {
    if(loading){
        if(overlay){
            
            return (
                <>
                <SpinnerComponent loading={loading} color={color} position="global" />
                {children}
                </>
            )
        }
        return (
            <FlexContainer>
                <ReactLoading type={type} color={color}/>
            </FlexContainer>
        );
    }

    if(error.length){
        return (
            <FlexContainer>
                <p>{error}</p>
                {onRetry && <Button onClick={onRetry}>Retry</Button>}
            </FlexContainer>
        );
    }

    return (
        <div>
            {children}
        </div>
    )
}