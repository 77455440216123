export default {
    getName: () => 'Dark',
    primary: '#4E7CFF',
    secondary: '#B57EDC',
    tertiary: '#282F48',
    html:  {
        backgroundColor: '#343844',
    },
    body: {
        backgroundColor: '#343844',
        color: '#ffffff',
    },
    button: {
        color: '#ffffff'
    },
    avatar: {
        backgroundColor: '#EEF2FF',
        fill: 'tertiary',
        color: '#ffffff'
    },
    gear: {
        backgroundColor: 'transparent',
        fill: '#659b5e',
        color: '#659b5e'
    },
    card: {
        host: {
            backgroundColor: '#4D505A'
        },
        draggableArea: {
            borderColor: '#ffffff',
            backgroundColor: '#4e515a',
            backgroundColorActive: '#A0B8FF',
        },
    },
    input: {
        backgroundColor: '#717A98',
        color: '#ffffff'
    },
    captchaBackground: '#282F48',
    captchaFill: '#ffffff',
    captchaImage: {
        rectangle: '#ffffff',
        triangle: '#8696D5',
        text: 'black'
    },
    scrollbarForeground:  '#0a0a0a',
    scrollbarBackground:  '#717a98',
    modalBodyBackground:  '#353839',
    modalBodyBackgroundSecondary:  '#353839',
    modalBodyColor:  '#ffffff',
    modalBodyColorSecondary:  '#ffffff',
    highlightBackground:  'linear-gradient(270deg, rgba(238, 242, 255, 0.5) 0%, #eef2ff 48%, rgba(238, 242, 255, 0.5) 100%, rgba(238, 242, 255, 0) 100%)',
    highlightColor:  '#000000',
    highlightBackgroundSecondary: 'linear-gradient(270deg, rgba(238, 242, 255, 0.5) 0%, #eef2ff 48%, rgba(238, 242, 255, 0.5) 100%, rgba(238, 242, 255, 0) 100%)',
}
