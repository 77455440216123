import React, { useState } from 'react';
import styled from 'styled-components';
import AppHeader from '../../../components/AppHeader';
import FlexContainer from '../../../components/FlexContainer';
import Avatar from '../../../components/Avatar';
import { useAbortableEffect, useLocale } from '../../../hooks';
import Action from '../Action';

const ResultContainer = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: ${props => props.theme.modalBodyBackgroundSecondary};
  color: ${props => props.theme.modalBodyColorSecondary};
  border-radius: 5px;
  font-size: 1.2em;
  padding:${props=>props.isKicked ? '48px' : '20px'};
  text-align:center;
`;

const Result = ({
        countdown = 0,
        title,
        subtitle,
        roundScores,
        nextRound,
        onNextRound,
        onSpectate,
        onMainMenu,
        isKicked,
        isEnd,
        isWin,
    }) => {
    const [countdown_,setCountdown] = useState(countdown);
    const locale = useLocale("game");

    const Grid = ({children,highlight,isWinner}) => {
        return (
            <div highlight={highlight} isWinner={isWinner} css={`
                background-image: ${
                    ({ highlight, theme }) => {
                        return highlight 
                            ? theme.highlightBackgroundSecondary
                            : 'none'
                    }};
                display:grid;
                grid-template-columns:48px auto 100px;
                padding: 5px 0;
                color: ${({ highlight, theme }) => highlight ? theme.highlightColor : 'inherit' };
            `}>
                {children}
            </div>
        );
    }

    const Cell = ({children}) => {
        return (
            <label css={`
                align-self:center;
                text-align:center;
                font-size:0.75em;
            `
        }>{children}</label>
        )
    }

    const renderResultRow = (data,index) => {
        return (
            <Grid key={data.id} highlight={data.isHighlight} isWinner={data.isWinner}>
                <Cell>{`${index}`}<sup>o</sup></Cell>
                <Avatar margin="0" size="small" label={data.name}/>
                <Cell>{data.point} ({data.rawPoint})</Cell>
            </Grid>
        );
    }

    const renderResultRows = (data,offset=1) => {
        return (
            <div
            css={`
                display:grid;
                grid-row-gap:8px;
                width:300px;
            `}>
                <Grid>
                    <div />
                    <div />
                    <Cell>{locale.result.point}</Cell>
                </Grid>
                {data.map((val,index)=>renderResultRow(val,index+offset))}
            </div>
        )
    }

    const renderResult = ({data}) => {
        if(data.length > 4){
            return (
                <FlexContainer flexDirection="row" height="auto" alignItems="flex-start">
                    <div>
                    {renderResultRows(data.filter((data,index)=>index<4),1)}
                    </div>
                    <div>
                    {renderResultRows(data.filter((data,index)=>index>=4),5)}
                    </div>
                </FlexContainer>
            );
        }

        return (
            <FlexContainer flexDirection="row" height="auto" alignItems="flex-start">
                {renderResultRows(data)}
            </FlexContainer>
        );
    }

    useAbortableEffect((status) => {
        if(!status.aborted){
            if(countdown_ !== null && countdown_ < 0){
                //stop
            }else{
                setTimeout(() => {
                    if(!status.aborted){
                        setCountdown(x=>x-1);
                    }
                }, 1000);
            }
        }
    },[countdown_]);

    return (
        <ResultContainer isKicked={isKicked}>
            {
                isKicked && !isEnd ?
                <label css={`font-size:2em;`}>{locale.resultAction.kicked}</label>
                :
                <>
                    <AppHeader
                      isEnd={isEnd}
                      isWin={isWin}
                      title={title} 
                      subtitle={subtitle}
                    />
                    {renderResult({data:roundScores})}
                </>
            }
            <Action 
              countdown={countdown_} 
              isKicked={isKicked} 
              isEnd={isEnd}
              nextRound={nextRound} 
              onSpectate={onSpectate} 
              onMainMenu={onMainMenu} 
              onNextRound={onNextRound}
            />
        </ResultContainer>
    )
}

export default Result;
