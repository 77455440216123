import React from 'react';
import PropTypes from 'prop-types';
import {Draggable} from 'react-beautiful-dnd';
import styled, {keyframes} from 'styled-components';
import {isSafari} from 'react-device-detect';
import ClubIcon from './assets/club.svg';
import DiamondIcon from './assets/diamond.svg';
import HeartIcon from './assets/heart.svg';
import SpadeIcon from './assets/spade.svg';
import SingleCard from './SingleCard';
import FlexContainer from '../../../../components/FlexContainer';
import setupAnimation from '../../../../utilities/cardAnimation';

const getIconByType = (type) => {
  switch (type) {
    case 'C':
      return ClubIcon;
    case 'D':
      return DiamondIcon;
    case 'H':
      return HeartIcon;
    case 'S':
      return SpadeIcon;
    default:
      return '';
  }
}

const AnimateBeforeCommitMove = keyframes`
    from{
        left:0;
    }
    to {
        left:-104px;
    }
`;

const AnimateCommitMoveCard = keyframes`
    from{
        left:0;
    }
    to {
        left:-185px;
    }
`;

const StyledCard = ({cardAnimation: animation, index, type, children}) => {
  const styles = setupAnimation({
    animation,
    index,
    direction: 'y',
    type,
  });
  return <div style={styles}>{children}</div>;
};

const StyledCardItem = ({cardAnimation: animation, index, type, children}) => {
  const styles = setupAnimation({
    animation,
    index,
    type,
  });
  return <div style={styles}>{children}</div>;
};

const CardElementContainer = styled.div`
    background-color:white;
    width:${props => props.width}px;
    height:${props => props.height}px;
    border-radius:8px;
    display: block;
    overflow:hidden;
    position: relative;
    margin:${props => props.margin};
    z-index:${props => props.iconAlignment === 'bottom'
  ? props.zIndex ? props.zIndex : -1
  : 1
};
    box-shadow: 0 0 4px rgba(0, 0, 0, .5);
    animation-name: ${props => {
  if (props.animation === 'AnimateBeforeCommitMove') {
    return AnimateBeforeCommitMove;
  } else if (props.animation === 'AnimateCommitMoveCard') {
    return AnimateCommitMoveCard;
  }
  return 'none';
}};
    animation-duration: ${props => props.animationSpeed};
    animation-timing-function: linear;
    animation-fill-mode: forwards;
`;

const CardElementWrapper = styled.div`
    padding:12px;
`;

const CardElementBackground = styled.div`
    background-size:${props => props.width}px ${props => props.height}px!important;
    width:${props => props.width}px;
    height:${props => props.height}px;
    background:url(${props => props.iconType}) no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.25;
    z-index:1;
`;

const CardElementLabel = styled.label`
    font-size:${props => props.labelSize}px;
    font-weight:bold;
    color:black;
    z-index:100;
`;

const CardElementImg = styled.img`
    width:${props => props.labelSize}px;
    height:${props => props.labelSize}px;
    z-index:100;
    position: ${props => props.iconAlignment === 'bottom' ? 'relative' : 'auto'};
    left: ${props => props.iconAlignment === 'bottom' ? '-2px' : '0px'};
    top: ${props => props.iconAlignment === 'bottom' ? '4px' : '0px'};
`;

const OpenCard = (props) => {
  const alignment = props.iconAlignment;
  const cardIndex = props.index;
  const currentPlayer = props.currentPlayer;
  let addAnimation = props.addAnimation;
  let cardAnimation = props.cardAnimation;
  let flexDirection = 'row';
  let justifyContent = 'space-between';
  let alignItems = 'center';
  if (alignment === 'bottom') {
    flexDirection = 'column';
    justifyContent = 'flex-start';
    alignItems = 'flex-start';
  }
  const iconType = getIconByType(props.data.type);
  const bgProps = {
    ...props,
    iconType,
  }

  const CardElement = () => {
    return (
      <CardElementContainer {...props}>
        <CardElementWrapper {...props}>
          <FlexContainer 
            height="auto" 
            flexDirection={flexDirection} 
            justifyContent={justifyContent}
            alignItems={alignItems}
          >
            <CardElementLabel  {...props} className='noselect'>{props.data.value}</CardElementLabel>
            <CardElementImg  {...props} className='noselect' src={iconType} alt="diamond"/>
          </FlexContainer>
        </CardElementWrapper>
        <CardElementBackground {...bgProps} />
      </CardElementContainer>
    )
  }
  const WrappedCardElement = () => (
    currentPlayer || addAnimation
      ? (
        <StyledCardItem
          cardAnimation={cardAnimation}
          type={props.cardAnimationType}
          index={cardIndex}
        >
          <StyledCard
            cardAnimation={cardAnimation}
            type={props.cardAnimationType}
            index={cardIndex}
          >
            {
              cardAnimation === 'initial'
                ? <SingleCard />
                : <CardElement/>
            }
          </StyledCard>
        </StyledCardItem>
      )
      : <CardElement/>
  )

  if (props.draggable) {
    return isSafari
      ? (
        <div onClick={() => props.manualClick({
          draggableId: props.id,
          sourceId: props.index
        })}>
          <WrappedCardElement/>
        </div>
      )
      : (
        <Draggable key={props.id} draggableId={props.id} index={props.index} isDragDisabled={props.isDragDisabled}>
          {provided => {
            return (
              <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}
                   style={{...props.styleDraggable, ...provided.draggableProps.style}}>
                <WrappedCardElement/>
              </div>
            )
          }}
        </Draggable>
      );
  }

  return <WrappedCardElement/>;
}

OpenCard.propTypes = {
  data: PropTypes.exact({
    type: PropTypes.oneOf(['C', 'D', 'H', 'S']).isRequired,
    value: PropTypes.oneOf(['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K']).isRequired
  }),
  width: PropTypes.number,
  height: PropTypes.number,
  labelSize: PropTypes.number,
  iconAlignment: PropTypes.oneOf(["right", "bottom"]),
  draggable: PropTypes.bool,
  index: PropTypes.number,
  id: PropTypes.string,
  margin: PropTypes.string,
  isDragDisabled: PropTypes.bool,
  styleDraggable: PropTypes.object,
  animation: PropTypes.string,
  animationSpeed: PropTypes.string,
  cardAnimation: PropTypes.string,
  cardAnimationType: PropTypes.string,
  addAnimation: PropTypes.bool,
  manualClick: PropTypes.func,
}

const largeScreen = window.matchMedia('(min-width: 1920px)');
const width = largeScreen ? 196 : 66;
const height = 1.4 * width;
OpenCard.defaultProps = {
  width: 96,
  height: 136,
  labelSize: 24,
  iconAlignment: 'right',
  draggable: false,
  index: 0,
  id: '',
  margin: '4px',
  isDragDisabled: false,
  styleDraggable: {},
  animation: 'none',
  animationSpeed: '1s',
  cardAnimation: 'none',
  cardAnimationType: 'currentPlayer',
  addAnimation: false,
  isLast: false,
  manualClick: null
}

export default OpenCard;
