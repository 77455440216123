import React,{useState} from 'react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import Input from '../Input';
import { ReactComponent as HelpSvg } from './assets/help.svg';
import { ReactComponent as SwitchSvg } from './assets/switch.svg';
import { ReactComponent as VolumeSvg } from './assets/volume.svg';
import {
    useTheme,
    useLocale,
    useAbortableEffect, 
    useIsFsRequesting,
} from '../../hooks';
import { Generator } from '../../utilities';
import { setCaptcha } from '../../actions/app';

const renderIcon = (comp)=>{
  return styled(comp)`
      border-radius:0.25em;
      border:0.1em;
      border-color:${props => props.theme.primary};
      border-style:solid;
      background-color:${props => props.theme.captchaBackground};
      & path{
          fill:${props => props.theme.captchaFill};
      }
      padding:0.25em;
      width:${props => props.size ? props.size+"px" : '1.75em'};
      height:${props => props.size ? props.size+"px" : '1.75em'};
      cursor:pointer;
  `;
}

const VolumeIcon = renderIcon(VolumeSvg);
const SwitchIcon = renderIcon(SwitchSvg);
const HelpIcon = renderIcon(HelpSvg);

export default (props)=>{
    let [captchaImg,setCaptchaImg] = useState('');
    const theme = useTheme();
    const locale = useLocale('captcha');
    const dispatch = useDispatch();
    const loading = useIsFsRequesting();
    const { addToast } = useToasts();
    const captcha = Generator.captcha({
        alert: addToast,
        locale,
    });
    const generateCaptchaImg = (status)=>{
        const captchaValue = captcha.get();
        dispatch(setCaptcha(captchaValue));
        Generator.textToImage({text:captchaValue,fill:{...theme.captchaImage}}).then((data)=>{
            if(status && !status.aborted){
                setCaptchaImg(data);
            }
        }).catch(err=>{
            console.log(err);
        });
    }

    useAbortableEffect(()=>{
        captcha.setVoice(locale.locale,'1');
    },[locale.locale]);

    useAbortableEffect((status)=>{
        if(!status.aborted && !loading){
            generateCaptchaImg(status);
        }
    },[theme.theme]);

    useAbortableEffect((status)=>{
        if(!status.aborted && !loading){
            generateCaptchaImg(status);
        }
    },[]);

    return (
      <div style={{display:"flex",justifyContent:"center"}}>
        <div>
            <img src={captchaImg} style={{width:'100%',marginBottom:'0.5em'}}/>
            <Input type={props.type} name={props.name} value={props.value} onChange={props.onChange}/>
        </div>
        <div style={{margin:"0 0 0 0.75em",display:"flex",flexDirection:'column',justifyContent:'space-between'}}>
            <VolumeIcon {...props} theme={theme} alt="volume" onClick={()=>{captcha.speak()}}/>
            <SwitchIcon {...props} theme={theme} alt="switch" onClick={()=>{captcha.switch();generateCaptchaImg({aborted:false});}}/>
            <HelpIcon {...props} theme={theme} alt="help" onClick={()=>{captcha.help();}}/>
        </div>
      </div>
    )
}
