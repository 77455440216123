import React from 'react';
import PropTypes from 'prop-types';
import {Droppable} from 'react-beautiful-dnd';
import FlexContainer from '../../../../components/FlexContainer';
import {getThemeColor} from '../../../../themes';
import {Game} from '../../../../utilities';
import OpenCard from './OpenCard';
import {DA_CURRENT_PLAYER, DA_OPEN_STACK} from './constant';

const renderCard = (props) => {
    return props.data.map((val,index)=>{
        let override = {...props,data:val};
        const id = Game.genIdDom(val,index);
        return (<OpenCard currentPlayer={true} index={index} key={id} {...override} id={id}/>);
    });
}

const CurrentPlayerCard = (props) => {
    const currentPlayerProps = {
        ...props,
        manualClick: ({ draggableId, sourceId }) => {
            props.onDragEnd({
                source: {
                    droppableId: DA_CURRENT_PLAYER,
                    index: sourceId,
                },
                destination: {
                    droppableId: DA_OPEN_STACK,
                    index: 0,
                },
                draggableId
            })
        }
    }
    return (
        <Droppable droppableId={DA_CURRENT_PLAYER} direction="horizontal" isDropDisabled={props.isDropDisabled} type={DA_CURRENT_PLAYER}>
            {provided=>{
                return (
                    <div id="game-section-main-current-card" ref={provided.innerRef} {...provided.droppableProps}>
                        <FlexContainer minWidth="56.42vw" height="auto" flexDirection="row" justifyContent="center">        
                            <div css={`
                                border-radius:8px;
                                padding:0 3px;
                                background-color:${props=>getThemeColor(props.theme.card.host.backgroundColor,props.theme)};
                            `}>
                                <FlexContainer height="auto" flexDirection="row" justifyContent="center">    
                                    {renderCard(currentPlayerProps)}
                                    {provided.placeholder}
                                </FlexContainer>
                            </div>
                        </FlexContainer>
                    </div>
                )
            }}
        </Droppable>
    );
}

CurrentPlayerCard.propTypes = {
    data:PropTypes.array.isRequired,
    draggable:PropTypes.bool,
    isDropDisabled:PropTypes.bool
}

CurrentPlayerCard.defaultProps = {
    draggable:false,
    isDropDisabled:false
}

export default CurrentPlayerCard;
