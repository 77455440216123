import React from 'react';
import styled from 'styled-components';
import Avatar from '../../../components/Avatar';

const Grid = props =>{
    const {children,style,body} = props;
    return (
        <div css={`
            padding:${body ? '10px 0' : '0'};
            display:grid;
            grid-auto-flow:column;
            grid-template-columns:30% repeat(auto-fill, 60px);
        `} style={style}>
            {children}
        </div>
    );
}

const Cell = (props)=>{
    const {children,style} = props;
    return (
        <label css={`
            align-self:center;
            text-align:center;
            font-size:0.75em;
            min-width: 60px;
        `
    } style={style}>{children}</label>
    )
}

const CellWithBB = ({children})=>{
    return (
        <div css={`
            border-bottom:1px solid #717a98;
            align-self:center;
            text-align:center;
            padding-bottom: 5px;
            margin-bottom: 15px;
            min-width: 60px;
        `}>
            {children}
        </div>
    );
}

const Header =  (props)=>{
    let arr=  [];
    for(let i=0;i<props.data.total;i++){
        arr.push(<CellWithBB key={i}><Cell>{i+1}</Cell></CellWithBB>);
    }

    return (
        <Grid>
            <CellWithBB><Cell>Round</Cell></CellWithBB>
            {arr}
            <CellWithBB><Cell>Total</Cell></CellWithBB>
        </Grid>
    );
}

const Row = (props)=>{
    const {data} = props;
    let arr = [];
    for(let i=0;i<data.length;i++){
        arr.push(<Cell key={i}>{data[i]}</Cell>);
    }

    return arr;
}

const Body =  (props)=>{
    let arr=  [];
    for(let i=0;i<props.data.data.length;i++){
        arr.push(
          <Grid body={true} key={i}>
              <div><Avatar margin="0" size="small" label={props.data.data[i].name}/></div>
              <Row data={props.data.data[i].details}/>
              <Cell>{props.data.data[i].point}</Cell>
          </Grid>
        )
    }

    return arr;
}

const LeaderboardContainer = styled.div`
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: ${props => props.theme.modalBodyBackground};
    color: ${props => props.theme.modalBodyColor};
    border-radius: 5px;
    display: grid;
    min-width: 600px;
    padding: 50px;
    font-size: 1.4em;
`;

const LeaderBoardContainer = styled.div`
    max-height: 208px;
    overflow: auto;
    margin-right: -17px;
    scrollbar-width: thin;
    scrollbar-color: ${props => props.theme.scrollbarForeground} ${props => props.theme.scrollbarBackground};
    
    ::-webkit-scrollbar {
        width: 17px;
    }
    
    ::-webkit-scrollbar-track {
        background: ${props => props.theme.scrollbarBackground};
        border-radius: 17px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.scrollbarForeground};
        border-radius: 17px;
        border: none;
    }
`;

const Leaderboard = ({gameScores})=>{
    if(!gameScores || !Object.keys(gameScores).length){
        gameScores = {
            total:0,
            data:[]
        }
    }

    return (
        <LeaderboardContainer>
            {
            gameScores.data.length ?
            <LeaderBoardContainer>
                <Header data={gameScores} />
                <Body data={gameScores} />
            </LeaderBoardContainer>
            :
            <label css={`
                margin:64px 0px;text-align:center;
            `}>No Data Found</label>
            }
        </LeaderboardContainer>
    )
}

export default Leaderboard;
