import React from 'react';
import styled from 'styled-components';
import AdsExample from './assets/ads.svg';

const AdsWrapper = styled.div`
  max-width: 734px;
`

export default ({ src, margin }) => {
    return (
        <AdsWrapper>
            <img src={src || AdsExample} style={{width:'100%', margin}} alt="ads"/>
        </AdsWrapper>
    )
}
