import {
    APP_SET_LANGUAGE,
    APP_SET_THEME,
    APP_SET_MODAL_OPENED,
    APP_SET_FAILURE,
    APP_SET_REQUEST,
    APP_SET_SUCCESS,
    APP_SET_CAPTCHA,
} from '../actions/constant';
import state from '../store/state';
import deepCopy from '../utilities/deepCopy';

const initialState = deepCopy(state);

export default (state = initialState,{type,payload})=>{
    switch(type){
        case APP_SET_LANGUAGE:
            return {...state,language:payload}
        case APP_SET_THEME:
            return {...state,theme:payload}
        case APP_SET_MODAL_OPENED:
            const { modalOpened, modalId } = payload
            return {...state,modalOpened,modalId}
        case APP_SET_REQUEST:
            return {...state,loading:payload}
        case APP_SET_SUCCESS:
            return {...state,loading:false}
        case APP_SET_FAILURE:
            return {...state,loading:false,error:payload}
        case APP_SET_CAPTCHA:
            return {...state,captcha:payload}
        default:
            return state;
    }
}
