import React from 'react';
import styled from 'styled-components';
import {getThemeColor} from '../../themes';
import {ReactComponent as AvatarSvg} from './assets/avatar.svg';
import {getSizeCss} from '../../utilities/view';
import FlexContainer from '../FlexContainer';

const AvatarIcon = styled(AvatarSvg).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => ![
        'borderRadius',
        'backgroundColor',
        'labelStyle',
        'highlight'
    ].includes(prop)
  })`
    display:${props => props.display};
    border-radius:${props => props.borderRadius};
    background-color:${props => props.backgroundColor ? getThemeColor(props.backgroundColor,props.theme) : getThemeColor(props.theme.avatar.backgroundColor,props.theme)};
    & path{
        fill:${props => props.fill ? getThemeColor(props.fill,props.theme) : getThemeColor(props.theme.avatar.fill,props.theme)};
    }
    color:${props => props.color ? getThemeColor(props.color,props.theme) : getThemeColor(props.theme.avatar.color,props.theme)};
    margin:${props=>props.margin};
    width:${props => props.width};
    height:${props => props.height};
    border:${props => props.highlight ? `4px solid ${props.theme.secondary}` : '0px'};
`;

export default (_props)=>{
    /*eslint-disable no-undef*/
    let props = {
        display = 'block',
        borderRadius = '6em',
        backgroundColor = '',
        fill = '',
        color = '',
        margin = 'auto',
        width = '6em',
        height = '6em',
        size = 'medium',
        label = '',
        highlight = false,
        labelStyle = {}
    } = _props;
    /*eslint-enable no-undef*/

    if(props.size){
        props = {...props,...getSizeCss(props.size)};
    }

    if(props.label){
        return (
            <FlexContainer height="auto" flexDirection="row" justifyContent="left">
                <AvatarIcon {...props} alt="avatar"/>
                <label style={{textAlign:"center",marginLeft:"0.75em",fontWeight:"bold",display:"block",fontSize:"0.75em",...props.labelStyle}}>{props.label}</label>
            </FlexContainer>
        );
    }

    return (
        <AvatarIcon {...props} alt="avatar"/>
    )
}
