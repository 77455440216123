import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: absolute;
    bottom: -8px;
    margin: auto;
    left: 50%;
    text-align: center;
    z-index: 200;
    background: black;
    border-radius: 8px;
    padding: 16px 8px;
    width: 128px;
    transform: translate3d(-50%, 0, 0);
`;

const Content = styled.label`
    color: white;
    font-size: 0.9em;
    top: -3px;
    position: relative;
`;

export default ({score})=>{
    return (
      <Wrapper>
        <Content>{`Holding : ${score}`}</Content>
      </Wrapper>
    )
}
