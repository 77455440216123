import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CardSvg } from './assets/card.svg';
import setupAnimation from '../../../../utilities/cardAnimation';

const CardIcon = styled(CardSvg).withConfig({
    shouldForwardProp: (prop) => ![
        'size',
        'position'
    ].includes(prop)
  })`
    width:${props => props.width};
    height:${props => props.height};
    position:${props=>props.position};
    left:${props=>props.left};
`;

export const getMultiplierSize = (size,defaultSize = 1)=>{
    switch(size){
        case 'small':
            return 0.5*defaultSize;
        case 'medium':
            return 1*defaultSize;
        case 'large':
            return 1.14*defaultSize;
        default:
            return 1*defaultSize;
    }
}

export const calcSizeCss = (size) => {
    let largeScreen = window.matchMedia('(min-width: 1920px)');
    let multiplier = 1;
    let width = 96;
    let height = 120;
    let unit = 'px';

    if(size === 'small'){
        multiplier = getMultiplierSize(size);
    }else if(size === 'medium'){
        multiplier = getMultiplierSize(size);
    }else if(size === 'large'){
        multiplier = getMultiplierSize(size);
    }else{
        width = size;
        height=size;
    }

    return{
        width:`${width*multiplier}${unit}`,
        height:`${height*multiplier}${unit}`
    }
}

const StyledCard = ({ cardAnimation: animation, index, children, cardAnimationType: type }) => {
    const styles = setupAnimation({
        animation,
        index,
        direction: 'y',
        type,
    });
    return <div style={styles}>{ children }</div>;
};

const StyledCardItem = ({ cardAnimation: animation, index, children, cardAnimationType: type }) => {
    const styles = setupAnimation({
        animation,
        index,
        type,
    });
    return <div style={styles}>{ children }</div>;
};

export default (_props) => {
    /*eslint-disable no-undef*/
    let props = {
        size = 'medium',
        position = 'relative',
        left = 0,
        user = {},
        animation = 'none',
        cardAnimation = 'none',
        index = null,
        cardAnimationType = 'opponentTopCenter',
        islast = 0,
    } = _props;
    /*eslint-enable no-undef*/

    if(props.size){
        props = {...props,...calcSizeCss(props.size)};
    }
    
    const remoteAnimations = ['drop', 'pick-closed', 'pick-opened'];
    if(
      remoteAnimations.includes(props.cardAnimation)
      && (  
        !props.user._turn
        || !props.islast
      )
    ) {
        props.cardAnimation = 'none';
    }
    
    const svgProps = {
        width: props.width,
        height: props.height,
        position: props.position,
        size: props.size,
        left: props.left,
    }
    
    return (
        props.user.player && props.cardAnimation
            ?   (
                    <StyledCardItem {...props}>
                        <StyledCard {...props}>
                            <CardIcon {...svgProps} alt="card"/>
                        </StyledCard>
                    </StyledCardItem>
                )
            : <CardIcon {...svgProps} alt="card"/>
    )
}
