import {setFailure,setSuccess,setRequest} from './app';
import {Game,Generator} from '../utilities';
import * as deck from './deck';
import * as timer from './timer';
import * as user from './user';

export const create = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {userid,username} = params;
      const docRef = db.collection("games").doc();
      await docRef.set({
        status:"",
        round:0,
        currentTurn:"",
        gameScores:{},
        roomCode:Generator.randomCode(),
      });

      await docRef.collection("users").doc(userid).set({
        name:username,
        host:true,
        player:true,
        spectator:false
      });

      dispatch(setSuccess(docRef.id));

      return docRef.id;
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const update = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {id,fields} = params;
      const docRef = db.collection("games").doc(id);        
      await docRef.update(fields);

      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const isExists = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {id} = params;
      const docRef = db.collection("games").where('roomCode', '==', id);
      const records = await docRef.get();
      const exists = records && records.docs && records.docs.length > 0;
      dispatch(setSuccess(exists));

      return exists ? records.docs[0] : [];
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const addNewPlayer = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {gameid,userid,username} = params;
      const colRef = db.collection("games").doc(gameid).collection("users");
      const full = await dispatch(isFull({
        params:{
          gameid:gameid
        }
      }));

      if(!full){
        await colRef.doc(userid).set({
          name:username,
          host:false,
          player:true,
          spectator:false
        });
      }else{
        throw new Error(`The room ${gameid} is full`);
      }
      
      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const isFull = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {gameid} = params;
      const colRef = db.collection("games").doc(gameid).collection("users");
      const colVal = await colRef.get();
      
      dispatch(setSuccess());

      return colVal.docs.length >= 8;
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const startNewRound = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {game,decks} = params;
      
      await dispatch(timer.create({
        params:{
          id:game.id,
          init:true
        }
      }));

      await dispatch(deck.create({
        params:{
          gameid:game.id,
          decks:decks,
      }}));

      await dispatch(update({
        params:{
          id:game.id,
          fields:{
              status:Game.STATUS.START,
              round:parseInt(game.round)+1
          }
      }}));
      
      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const addRoundScores = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {gameid,roundid,scores,rawScores} = params;
      const rs = db.collection('games').doc(gameid).collection('roundScores').doc(roundid+"");
      const rsRaw = db.collection('games').doc(gameid).collection('rawRoundScores').doc(roundid+"");
      await rs.set(scores);
      await rsRaw.set(rawScores);
      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const changeToSpectator = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {gameid,userid} = params;

      const rs = db.collection('games').doc(gameid).collection('users').doc(userid);
      await rs.update({
        spectator:true
      });

      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const changeToPlayer = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {gameid,userid} = params;

      const rs = db.collection('games').doc(gameid).collection('users').doc(userid);
      await rs.update({
        spectator:false,
        player:true
      });

      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const kickPlayers = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {gameid,kicked} = params;

      for(let i=0;i<kicked.length;i++){
        await dispatch(changeToSpectator({
          params:{
            gameid:gameid,
            userid:kicked[i]
          }
        }));
      }

      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const endGame = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {gameid,players,round,gameScores} = params;
      
      let process = [];
      for(let i=0;i<players.length;i++){
        process.push(dispatch(changeToPlayer({
          params:{
            gameid:gameid,
            userid:players[i].id
          }
        })));
        
        const docGS = db.collection('scores').doc(players[i].id);
        const refGS = await docGS.get();
        const dataGS = refGS.data();
        let score =  parseInt(dataGS ? dataGS.score: 0);
        if(Number.isNaN(score)){
          score = 0;
        }

        let userScore =  parseInt(gameScores[players[i].id]/parseInt(round));
        if(Number.isNaN(userScore)){
          userScore = 0;
        }

        await docGS.set({
          "score":(score+userScore)
        },{merge:true});
      }
      
      await Promise.all(process);

      await dispatch(update({
        params:{
          id:gameid,
          fields:{
            status:Game.STATUS.NONE,
            round:0,
            currentTurn:"",
            gameScores:{}
          }
        }
      }));

      const rs = await db.collection('games').doc(gameid).collection('roundScores').get();
      const rrs = await db.collection('games').doc(gameid).collection('rawRoundScores').get();
      rs.forEach(element => {
        element.ref.delete();
      });
      rrs.forEach(element => {
        element.ref.delete();
      });
      
      await db.collection('decks').doc(gameid).delete();
      await db.collection('timers').doc(gameid).delete();

      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const randomMatch = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {userid,gameid} = params;
      const colRefUsers = db.collection("users");
      const qsEmptyStatus = await colRefUsers.where("currentGame", "==", "").get();
      
      for(let i=0;i<qsEmptyStatus.docs.length;i++){
        const data = await qsEmptyStatus.docs[i].data();
        if(qsEmptyStatus.docs[i].id !== userid){
          try{
            let process = [];
            process.push(dispatch(addNewPlayer({
              params:{
                userid:qsEmptyStatus.docs[i].id,
                gameid:gameid,
                username:data.name
              }
            })));
            process.push(dispatch(user.update({
              params:{
                id:qsEmptyStatus.docs[i].id,
                fields:{
                  currentGame:gameid
                }
              }
            })));

            await Promise.all(process);
          }catch(err){
            break;
          }
        }
      }

      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const removePlayer = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {gameid,userid} = params;

      await db.collection('games').doc(gameid).collection('users').doc(userid).delete();
      await dispatch(user.update({
        params:{
          id:userid,
          fields:{
            currentGame:""
          }
        }
      }));

      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}
