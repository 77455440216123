import React from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import {OpponentCard, CurrentPlayerCard, TableOpenCard, StackCard} from '../shares/Card';
import FlexContainer from '../../../components/FlexContainer';
import Ads from '../../../components/Ads';
import AdsImage from './assets/ads-2.svg';
import Info from '../shares/Info';

const MainTop = ({ players, cardAnimation, totalHolding }) => {
    const renderPlayers = ()=>{
        if([7].includes(players.length)){
            return (
                <>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopOneThirdLeft" cardAnimation={cardAnimation} user={players[2]}/>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopCenter" cardAnimation={cardAnimation} user={players[3]}/>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopOneThirdRight" cardAnimation={cardAnimation} user={players[4]}/>
                </>
            );
        }else if([6].includes(players.length)){
            return (
                <>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopLeft" cardAnimation={cardAnimation} user={players[2]}/>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopRight" cardAnimation={cardAnimation} user={players[3]}/>
                </>
            );
        }else if([5].includes(players.length)){
            return (
                <>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopCenter" cardAnimation={cardAnimation} user={players[2]}/>
                </>
            );
        }else if([4].includes(players.length)){
            return (
                <>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopLeft" cardAnimation={cardAnimation} user={players[1]}/>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopRight" cardAnimation={cardAnimation} user={players[2]}/>
                </>
            );
        }else if([3].includes(players.length)){
            return (
                <>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopCenter" cardAnimation={cardAnimation} user={players[1]}/>
                </>
            );
        }else if([2].includes(players.length)){
            return (
                <>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopLeft" cardAnimation={cardAnimation} user={players[0]}/>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopRight" cardAnimation={cardAnimation} user={players[1]}/>
                </>
            );
        }else if([1].includes(players.length)){
            return (
                <>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentTopCenter" cardAnimation={cardAnimation} user={players[0]}/>
                </>
            );
        }
    }

    let columns = 'auto auto auto';
    if([7].includes(players.length)){
    }else if([6,4,2].includes(players.length)){
        columns = 'auto auto';
    }else if([5,3,1].includes(players.length)){
        columns = 'auto';
    }

    return (
        <div css={`
            width: 100%;
            display: grid;
            grid-template-columns: ${columns};
        `}
        >
            {renderPlayers()}
        </div>
    );
}

export default ({
  totalClosed, 
  onTimerEnd, 
  isTimerOn, 
  timerType, 
  timerId, 
  timerDuration, 
  players, 
  animation="", 
  message, 
  currentPlayerCard, 
  wildCard, 
  openCard, 
  currentCard, 
  onDragEnd, 
  isChainable = false, 
  isDraggable = false, 
  playerCanPick = false,
  cardAnimation = 'none',
  currentTurn = false,
  totalHolding = {},
  prevLCStatus = '',
}) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <FlexContainer justifyContent="space-between" padding="2em 0 0 0">
                <MainTop
                  cardAnimation={cardAnimation} 
                  players={players}
                  totalHolding={totalHolding}
                />
                <div style={{ width: '100%' }} id="game-section-main-center">
                  <FlexContainer width="100%" margin="0 20px" height="auto" flexDirection="row" justifyContent="space-around">
                    <div style={{margin: '0 10%'}}>
                      <StackCard
                        cardAnimation={cardAnimation}
                        total={totalClosed}
                        size="large"
                        data={wildCard}
                        draggable={playerCanPick}
                        onDragEnd={onDragEnd}
                      />
                    </div>
                    <div style={{margin: '0 10%'}}>
                      <TableOpenCard
                        cardAnimation={cardAnimation}
                        animation={animation}
                        chainable={isChainable}
                        draggable={playerCanPick}
                        iconAlignment="right"
                        opened={openCard}
                        current={currentCard}
                        currentTurn={currentTurn}
                        prevLCStatus={prevLCStatus}
                        onDragEnd={onDragEnd}
                      />
                    </div>
                  </FlexContainer>
                </div>
                <Ads margin="15px 0 0" src={AdsImage} />
                <div>
                  <FlexContainer height="auto">
                    <Info text={message} timerDuration={timerDuration} isTimerOn={isTimerOn} onTimerEnd={onTimerEnd} timerType={timerType} timerId={timerId}/>
                    <CurrentPlayerCard onDragEnd={onDragEnd} cardAnimation={cardAnimation} data={currentPlayerCard} draggable={isDraggable} isDragDisabled={playerCanPick && !isChainable} />
                  </FlexContainer>
                </div>
            </FlexContainer>
        </DragDropContext>
    )
}
