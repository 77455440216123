import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import { DA_OPEN_STACK, DA_CURRENT_PLAYER } from './constant';
import OpenCard from './OpenCard';
import FlexContainer from '../../../../components/FlexContainer';
import { getThemeColor } from '../../../../themes';
import { Game } from '../../../../utilities';

const OpenedCardContainer = styled.div`
    display:grid;
    grid-template-columns: repeat(auto-fill,minmax(1px,max-content));
    justify-content:center;
    align-items:center;
    margin-right:4px;
`;

const OpenedCardWrapper = styled.div`
    width:${props => props.width}px;
    height:${props => props.height}px;
    border-radius:8px;
    background-color:${props => {
        return getThemeColor(props.theme.card.draggableArea.backgroundColor,props.theme)
    }};
    border:1px;
    border-color:${props => {
        return getThemeColor(props.theme.card.draggableArea.borderColor,props.theme);
    }};
    border-style:dotted;
    margin-left:'4px';
    z-index:-1;
`;

const DroppableContainer = styled.div`
    display:grid;
    grid-template-columns: repeat(auto-fill,minmax(1px,max-content));
    justify-content:center;
    align-items:center;
`;

const DroppableWrapper = styled.div`
    width:${props => {
        if(props.current && props.current.length && props.isDraggingOver){
            return props.width+12+'px';
        }
        return props.width+'px';
    }};
    height:${props => {
        if(props.current && props.current.length && props.isDraggingOver){
            return props.height+12+'px';
        }
        return props.height+'px';
    }};
    border-radius:8px;
    background-color:${props => {
        if(props.isDraggingOver){
            return getThemeColor(props.theme.card.draggableArea.backgroundColorActive,props.theme);
        }else{
            return getThemeColor(props.theme.card.draggableArea.backgroundColor,props.theme)
        }
    }};
    border:1px;
    border-color:${props => {
        return getThemeColor(props.theme.card.draggableArea.borderColor,props.theme);
    }};
    border-style:dotted;
    margin-left:${props => {
        if(props.current && props.current.length && props.isDraggingOver){
            return '0px';
        }
        return "4px";
    }};
    z-index:-1;
`;

const TableOpenCard = (props) => {
    const { 
        current, 
        currentTurn, 
        opened, 
        cardAnimation, 
        prevLCStatus,
    } = props;

    const tableOpenProps = {
        ...props,
        manualClick: ({ draggableId, sourceId }) => {
            props.onDragEnd({
                source: {
                    droppableId: DA_OPEN_STACK,
                    index: sourceId,
                },
                destination: {
                    droppableId: DA_CURRENT_PLAYER,
                    index: 0,
                },
                draggableId
            })
        }
    }
    const renderOpened = () => {
        let viewOpened = opened.filter((val,index) => {
            return index === opened.length-1 || index === opened.length-2 || index === opened.length-3;
        }).map((val,index) => {
            const id = Game.genIdDom(val,index);
            return <OpenCard 
              cardAnimation={cardAnimation} 
              cardAnimationType="openCard"
              addAnimation={true}
              styleDraggable={{width:props.width+'px',height:props.height+'px'}} 
              zIndex={1+index} 
              key={id} 
              id={id} 
              index={index} 
              {...tableOpenProps} 
              data={val} 
              margin="0 0 0 0" 
              animation={props.animation === 'AnimateCommitMoveCard' && (index !== viewOpened.length-1) 
                ? props.animation 
                : 'none'} 
              animationSpeed={`${0.5+index}s`}
            />
        });

        if(viewOpened.length){
            return viewOpened;
        }
    }

    return (
        <Droppable droppableId={DA_OPEN_STACK} isDropDisabled={props.draggable && !props.chainable} type={DA_CURRENT_PLAYER}>
            {(provided,snapshot) => {
                return (
                    <FlexContainer height="auto" flexDirection="row">
                        <OpenedCardContainer>
                            <OpenedCardWrapper />
                            {renderOpened()}
                        </OpenedCardContainer>

                        <DroppableContainer 
                          ref={provided.innerRef} 
                          {...provided.droppableProps}
                        >
                            <DroppableWrapper 
                              current={current} 
                              width={props.width} 
                              height={props.height} 
                              isDraggingOver={snapshot.isDraggingOver}
                            >
                                {provided.placeholder}
                            </DroppableWrapper>
                            {
                                current && current.length 
                                    ? current.map((val,index) => {
                                        const isLast = index === current.length - 1;
                                        const addDropAnimation = !currentTurn && isLast;
                                        let droppableAnimation = cardAnimation;
                                        if(index > 0 && prevLCStatus === Game.STATUS.DROPPED_CHAINABLE) {
                                            droppableAnimation = 'none';
                                        }
                                        return <OpenCard  
                                            {...props} 
                                            animation={
                                                
                                                props.animation === 'AnimateBeforeCommitMove' 
                                                  ? props.animation 
                                                  : 'none'
                                            } 
                                            animationSpeed={`${0.5+index}s`} 
                                            addAnimation={addDropAnimation}
                                            cardAnimation={droppableAnimation}
                                            cardAnimationType="droppable"
                                            key={index} 
                                            zIndex={10} 
                                            data={val} 
                                            margin="0 0 0 4px" 
                                            draggable={false}
                                            isLast={index === current.length - 1}
                                        />
                                    }) 
                                    : ''
                            }
                        </DroppableContainer>
                    </FlexContainer>
                )
            }}
        </Droppable>
    );
}

TableOpenCard.propTypes = {
    opened:PropTypes.array,
    current:PropTypes.array,
    width:PropTypes.number,
    height:PropTypes.number,
    labelSize:PropTypes.number,
    draggable:PropTypes.bool,
    cardAnimation:PropTypes.string,
    prevLCStatus:PropTypes.string,
}

const largeScreen = window.matchMedia('(min-width: 1920px)');
const width = largeScreen ? 196 : 66;
const height = 1.4 * width;
TableOpenCard.defaultProps = {
    width:96,
    height:136,
    draggable:false,
}

export default TableOpenCard;
