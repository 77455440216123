import React from 'react';
import { OpponentCard} from '../shares/Card';
import {useLocale} from '../../../hooks';
import Container from '../../../components/Container';
import FlexContainer from '../../../components/FlexContainer';
import Button from '../../../components/Button';
import Setting from '../../../components/Setting';

export default ({
  players,
  onShow,
  onMove,
  showDisabled = false,
  moveDisabled=false,
  onClickLeaderboard,
  cardAnimation = 'none',
  totalHolding = {},
}) => {
    const locale = useLocale();

    const renderPlayers = ()=>{
        if([7].includes(players.length)){
            return (
                <>
                    <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentRightTop" cardAnimation={cardAnimation} flip={true} user={players[5]} styleGrid={{margin:'1em 0 2em 0'}}/>
                    <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentRightBottom" cardAnimation={cardAnimation} flip={true} user={players[6]} />
                </>
            );
        }else if([6].includes(players.length)){
            return (
                <>
                    <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentRightTop" cardAnimation={cardAnimation} flip={true} user={players[4]} styleGrid={{margin:'1em 0 2em 0'}}/>
                    <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentRightBottom" cardAnimation={cardAnimation} flip={true} user={players[5]} />
                </>
            );
        }else if([5].includes(players.length)){
            return (
                <>
                    <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentRightTop" cardAnimation={cardAnimation} flip={true} user={players[3]} styleGrid={{margin:'1em 0 2em 0'}}/>
                    <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentRightBottom" cardAnimation={cardAnimation} flip={true} user={players[4]} />
                </>
            );
        }else if([4].includes(players.length)){
            return (
                <>
                    <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentRightMiddle" cardAnimation={cardAnimation} flip={true} user={players[3]}/>
                </>
            );
        }else if([3].includes(players.length)){
            return (
                <>
                    <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentRightMiddle" cardAnimation={cardAnimation} flip={true} user={players[2]}/>
                </>
            );
        }
    }

    return (
        <FlexContainer justifyContent="space-between">
            <Setting onClickLeaderboard={onClickLeaderboard} />
            <Container padding="1em 0 0 0" backgroundColor='none' minWidth="160px">
                <FlexContainer height="auto" >
                    <FlexContainer height="auto" justifyContent="start">
                        {renderPlayers()}
                    </FlexContainer>
                </FlexContainer>
            </Container>
            <div>
              <FlexContainer height="136px">
                <Button disabled={moveDisabled} width="6.5em" style={{marginBottom:'0.5em',display:'block'}} onClick={onMove}>{locale.game.move}</Button>
                <Button disabled={showDisabled} width="6.5em" backgroundColor={"secondary"} onClick={onShow}>{locale.game.show}</Button>
              </FlexContainer>
            </div>
        </FlexContainer>
    );
}
