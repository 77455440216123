import RandomCodes from 'random-codes';
import Speech from 'speak-tts';
import {svg2png} from 'svg-png-converter';

const numberChars = "123456789";
const UpperChars = "ABCDEFGHJKMNPQRSTUVWXYZ";
const LowerChars = UpperChars.toLowerCase();

export const randomCode = (args)=>{
    args = Object.assign({
        caseSensitive:true,
        alphaNumeric:true,
        length:7
    },args);

    let chars = numberChars;
    if(args.alphaNumeric){
        chars += UpperChars;
        if(args.alphaNumeric){
            chars += LowerChars;
        }
    }

    let config = {
        // A string containing available chars
        chars:chars,
        
        // Separator char used to divide code parts
        separator: '-',
        
        // Char used to mask code
        mask: '*',
        
        // Number of parts the code contains
        parts: 1,
        
        // Size of each part
        part_size: args.length,
        
        // Function used to get a random char from the chars pool 
        // (Please use a better one) 
        getChar: function (pool) {
            var random = Math.floor(Math.random() * pool.length);
            return pool.charAt(random);
        }
    };

    return new RandomCodes(config).generate();
}

export const textToImage = async (props)=>{
    return await svg2png({ 
        input: `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="345" height="65" viewBox="0 0 345 65">
        <defs>
            <clipPath id="a">
                <rect width="345" height="65" rx="8" transform="translate(0 0.341)" fill="#fff"/>
            </clipPath>
        </defs>
        <g transform="translate(0 -0.341)">
            <rect width="345" height="65" rx="8" transform="translate(0 0.341)" fill="${props.fill.rectangle}"/>
                <g transform="translate(0 0)" clip-path="url(#a)">
                    <path d="M23.314,0,65.059,63.078,0,52.326Z" transform="translate(10.376 52.051) rotate(-48)" fill="${props.fill.triangle}"/>
                    <path d="M34.6,0,56.272,35.677,0,43.857Z" transform="translate(178.84 48.337) rotate(-145)" fill="${props.fill.triangle}"/>
                    <path d="M13.1,0l44.98,101.026L0,92.171Z" transform="translate(228.874 19.149) rotate(-38)" fill="${props.fill.triangle}"/>
                </g>
                <text transform="translate(71 46.341)" fill="${props.fill.text}" font-size="50" font-family="AcuminPro-Bold, Acumin Pro" font-weight="700">
                    <tspan x="0" y="0">${props.text}</tspan>
                </text>
            </g>
    </svg>
      `.trim(), 
        encoding: 'dataURL', 
        format: 'png'
    });
}

export const captcha = (args = {})=>{
    
    let availableVoices = [];
    let speech;
    try{
        speech = new Speech();
        speech.init({
            volume: 1,
            lang: "en-GB",
            rate: 0.75,
            pitch: 1
          }).then((data) => {
            availableVoices = data && data.voices ? data.voices : [];
        }).catch(e => {
        })
    }catch(err){

    }

    let value = randomCode();
    const toast = (content, type) => args.alert(content, {
        appearance: type,
        autoDismiss: true,
    });

    return {
        get:()=>value,
        setVoice:(lang)=>{
            let selected = '';
            for(let i=0;i<availableVoices.length;i++){
                if(availableVoices[i].lang.includes(lang)){
                    selected = availableVoices[i].name;
                    break;
                }
            }

            if(selected){
                try{
                    speech.setVoice(selected);
                }catch(err){
                    args.alert ? toast(err.message, 'error') : console.error(err);
                }
            }
        },
        setLocale:(locale)=>{
            args.locale = locale;
        },
        help:()=>{
            args.alert ? toast(args.locale.help, 'info') : console.log(args);
        },
        switch:()=>{
            value = randomCode();
        },
        speak:async()=>{
             // will throw an exception if not browser supported
            if(speech && speech.hasBrowserSupport()) { // returns a boolean
                try{
                    speech.speak({
                        queue: false, // current speech will be interrupted,
                        lang:args.language,
                        text:value.split("").join(",")//.replace(/\,/g,".")
                    });
                }catch(err){
                    args.alert ? toast(err.message, 'error') : console.error(err);
                }
            }else{
                args.alert ? toast(args.locale.notSupported, 'error') : console.log(args);
            }
        }
    }
}
