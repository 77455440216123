import {
  TIMER_SINGLE_CARD_ANIMATION,
  INITIAL_CARD_PER_PLAYER,
  SINGLE_CARD_WIDTH,
} from './game';

const duration = TIMER_SINGLE_CARD_ANIMATION;
const maxDelay = duration * INITIAL_CARD_PER_PLAYER;
const cardWidth = SINGLE_CARD_WIDTH;

const animateY = (index, drop) => ({
  animationDuration: `${duration}s`,
  animationFillMode: 'forwards',
  animationTimingFunction: 'linear',
  animationDelay: drop ? 0 : `${maxDelay - (index * duration)}s`,
});

const animateX = (index, start) => ({
  animationDuration: `${duration}s`,
  animationFillMode: 'forwards',
  animationTimingFunction: 'linear',
  animationDelay: `${ start
    ? maxDelay - (index * duration)
    : 0
  }s`,
});

const animateCurrentPlayerX = (index, initial, end) => ({
  transform: `${initial
    ? `translateX(calc(280% + ${(index - (2 * index + 2)) * cardWidth}px))`
    : end
      ? `translateX(calc(0% + ${(-1 * index + 3) * cardWidth}px))`
      : 'none'
  }`,
  animationName: `${initial
    ? `default-card-item--${index}`
    : end
      ? 'final-card'
      : 'none'
  }`,
});

const animateCurrentPlayerY = (initial) => {
  return {
    transform: `${initial ? 'translateY(-235%)' : 'none'}`,
    animationName: `${initial ? 'default-card' : 'none'}`,
  }
};

const animateOpponentTopCenterX = (start, drop, pickOpened) => ({
  transform: `${start
    ? pickOpened 
      ? 'translateX(calc(0% + 104px))'
      : `translateX(calc(0% - ${3 * cardWidth}px))`
    : drop
      ? 'translateX(0%)'
      : 'none'
  }`,
  animationName: `${start
    ? 'final-card'
    : drop 
      ? 'drop-top-center-x'
      : 'none'
  }`,
});

const animateOpponentTopRightX = start => ({
  transform: `${start
    ? `translateX(calc(0% - ${5 * cardWidth}px))`
    : 'none'
  }`,
  animationName: `${start
    ? 'final-card'
    : 'none'
  }`,
});

const animateOpponentTopLeftX = (start, drop, pickOpened) => ({
  transform: `${start
    ? pickOpened
      ? 'translateX(calc(0% + 312px))'
      : `translateX(calc(0% - ${cardWidth}px))`
    : drop
      ? 'translateX(0%)'
      : 'none'
  }`,
  animationName: `${start
    ? 'final-card'
    : drop
      ? 'drop-top-left-x'
      : 'none'
  }`,
  animationDelay: '.8s !important'
});

const animateOpponentTopOneThirdRightX = (start, drop, pickOpened) => ({
  transform: `${start
    ? pickOpened
      ? 'translateX(calc(0% - 104px))'
      : `translateX(calc(0% - ${6 * cardWidth}px))`
    : drop 
      ? 'translateX(0%)'
      : 'none'
  }`,
  animationName: `${start
    ? 'final-card'
    : drop 
      ? 'drop-top-onethird-right-x'
      : 'none'
  }`,
});

const animateOpponentTopOneThirdLeftX = (start, drop, pickOpened) => ({
  transform: `${start
    ? pickOpened
      ? 'translateX(calc(0% + 416px))'
      : `translateX(0%)`
    : drop
      ? 'translateX(0%)'
      : 'none'
  }`,
  animationName: `${start
    ? 'final-card'
    : drop
      ? 'drop-top-onethird-left-x'
      : 'none'
  }`,
});

const animateOpponentLeftX = (start, drop, pickOpened) => ({
  transform: `${start
    ? pickOpened
      ? 'translateX(calc(0% + 1040px))'
      : `translateX(calc(0% + ${2 * cardWidth}px))`
    : drop 
      ? 'translateX(0%)'
      : 'none'
  }`,
  animationName: `${start
    ? 'final-card'
    : drop 
      ? 'drop-left-x'
      : 'none'
  }`,
});

const animateOpponentRightX = (start, drop, pickOpened) => ({
  transform: `${start
    ? pickOpened
      ? 'translateX(calc(0% - 416px))'
      : `translateX(calc(0% - ${8 * cardWidth}px))`
    : drop 
      ? `translateX(0%)`
      : 'none'
  }`,
  animationName: `${start
    ? 'final-card'
    : drop 
      ? 'drop-right-x'
      : 'none'
  }`,
});

const animateOpponentTopY = (start, drop, faster) => {
  return {
    transform: `${
      start
        ? 'translateY(116%)'
        : drop
        ? 'translateY(0%)'
        : 'none'
    }`,
    animationName: `${
      start
        ? 'default-card'
        : drop
        ? 'drop-top-y'
        : 'none'
    }`,
    ...(faster && { animationDelay: '.75s !important'}),
  }
};

const animateOpponentSideMiddleY = (start, drop) => ({
  transform: `${start 
    ? 'translateY(-49%)' 
    : drop 
      ? 'translateY(0%)'
      : 'none'
  }`,
  animationName: `${
    start 
      ? 'default-card' 
      : drop 
        ? 'drop-middle-y'
        : 'none'
  }`,
});

const animateOpponentSideTopY = (start, drop) => ({
  transform: `${
    start 
      ? 'translateY(-26%)' 
      : drop
        ? 'translateY(0%)'
        : 'none'
  }`,
  animationName: `${
    start 
      ? 'default-card' 
      : drop
        ? 'drop-side-top-y'
        : 'none'
  }`,
});

const animateOpponentSideBottomY = (start, drop) => ({
  transform: `${
    start 
      ? 'translateY(-135%)' 
      : drop 
        ? 'translateY(0%)'
        : 'none'
  }`,
  animationName: `${
    start 
      ? 'default-card' 
      : drop
        ? 'drop-side-bottom-y'
        : 'none'
  }`,
});

const animateWildCardX = (initial, end) => ({
  transform: `${initial || end
    ? `translateX(calc(0% + ${.5 * cardWidth}px))`
    : 'none'
  }`,
  animationName: `${end
    ? 'final-card'
    : 'none'
  }`,
  zIndex: -1,
});

const animateOpenCardX = (initial, end) => ({
  transform: `${initial || end
    ? `translateX(calc(0% - ${3 * cardWidth}px))`
    : 'none'
  }`,
  animationName: `${end
    ? 'final-card'
    : 'none'
  }`,
  zIndex: -1,
});

const animateDroppable = (dropIn, persistDroppable) => ({
  opacity: persistDroppable ? 1 : 0,
  animationName: dropIn ? 'fade-in' : 'none',
  animationDelay: '0s',
});

const setupAnimation = options => {
  const { animation, index, direction = 'x', type } = options;
  const initial = animation === 'initial';
  const pickOpened = animation === 'pick-opened';
  const pickClosed = animation === 'pick-closed';
  const start = initial || pickOpened || pickClosed;
  const end = animation === 'final';
  const drop = animation === 'drop';
  const dropIn = animation === 'droppable-in';
  const finishDrop = animation === 'finish-drop';
  const beforeCommitMove = animation === 'before-commit-move';
  const persistDroppable = start || finishDrop || beforeCommitMove;
  const tops = {
    top: (document.getElementById('game-section-main-top') || {}).offsetTop || 0,
    center: (document.getElementById('game-section-main-center') || {}).offsetTop || 0,
    currentCard: (document.getElementById('game-section-main-current-card') || {}).offsetTop || 0,
  }
  
  const isHorizontal = direction === 'x';
  let styles = isHorizontal
    ? animateX(index, start)
    : animateY(index, drop);
  let cardStyles = {};
  
  switch(type) {
    case 'currentPlayer':
      cardStyles = isHorizontal
        ? animateCurrentPlayerX(index, initial, end)
        : animateCurrentPlayerY(initial, tops);
      break;
      
    case 'opponentTopCenter':
      cardStyles = isHorizontal
        ? animateOpponentTopCenterX(start, drop, pickOpened)
        : animateOpponentTopY(start, drop, false, tops);
      break;
      
    case 'opponentTopRight':
      cardStyles = isHorizontal
        ? animateOpponentTopRightX(start)
        : animateOpponentTopY(start, drop, false, tops);
      break;
      
    case 'opponentTopLeft':
      cardStyles = isHorizontal
        ? animateOpponentTopLeftX(start, drop, pickOpened)
        : animateOpponentTopY(start, drop, true, tops);
      break;

    case 'opponentTopOneThirdRight':
      cardStyles = isHorizontal
        ? animateOpponentTopOneThirdRightX(start, drop, pickOpened)
        : animateOpponentTopY(start, drop, false, tops);
      break;

    case 'opponentTopOneThirdLeft':
      cardStyles = isHorizontal
        ? animateOpponentTopOneThirdLeftX(start, drop, pickOpened)
        : animateOpponentTopY(start, drop, true, tops);
      break;

    case 'opponentLeftMiddle':
      cardStyles = isHorizontal
        ? animateOpponentLeftX(start, drop, pickOpened)
        : animateOpponentSideMiddleY(start, drop);
      break;
      
    case 'opponentLeftTop':
      cardStyles = isHorizontal
        ? animateOpponentLeftX(start, drop, pickOpened)
        : animateOpponentSideTopY(start, drop);
      break;

    case 'opponentLeftBottom':
      cardStyles = isHorizontal
        ? animateOpponentLeftX(start, drop, pickOpened)
        : animateOpponentSideBottomY(start, drop);
      break;
      
    case 'opponentRightMiddle':
      cardStyles = isHorizontal
        ? animateOpponentRightX(start, drop, pickOpened)
        : animateOpponentSideMiddleY(start, drop);
      break;

    case 'opponentRightTop':
      cardStyles = isHorizontal
        ? animateOpponentRightX(start, drop, pickOpened)
        : animateOpponentSideTopY(start, drop);
      break;

    case 'opponentRightBottom':
      cardStyles = isHorizontal
        ? animateOpponentRightX(start, drop, pickOpened)
        : animateOpponentSideBottomY(start, drop);
      break;

    case 'wildCard':
      cardStyles = isHorizontal
        ? animateWildCardX(initial, end)
        : {};
      break;

    case 'openCard':
      cardStyles = isHorizontal
        ? animateOpenCardX(initial, end)
        : {};
      break;

    case 'droppable':
      cardStyles = isHorizontal
        ? animateDroppable(dropIn, persistDroppable)
        : {};
      break;
      
    default:
      break;
  }
  
  return {
    ...styles,
    ...cardStyles,
  };
}

export default setupAnimation;
