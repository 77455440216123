import { useFirestoreConnect,isLoaded,isEmpty} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useAbortableEffect } from './index.js';

const USERS = 'users';
const GAMES = 'games';
const ROUNDSCORES = 'roundScores';
const RAWROUNDSCORES = 'rawRoundScores';
const DECKS = 'decks';
const TIMERS = 'timers';

export const useUsers = (id,props = {})=>{
    const {onLoaded, single = true} = props;

    if(typeof id === 'number'){
        id = id+'';
    }

    if(single){
        if(!id){
            id = "0";
        }
    }

    useFirestoreConnect(() => [
        {collection: USERS, doc:id}
    ]);
    
    let data = useSelector(state => state.firestore.ordered[USERS]);
    data = (data && data.length === 1) ? data[0] : data;

    const loaded = isLoaded(data);
    const empty = isEmpty(data);

    useAbortableEffect((status)=>{
        if(loaded){
            if(!status.aborted){
                onLoaded && onLoaded({
                    data:data,
                    empty:empty
                });
            }
        }
    },[data]);
    
    return {
        data:data,
        loaded:loaded,
        empty:empty
    }
}

export const useGames = (id,props = {})=>{
    const {onLoaded, single = true} = props;

    if(typeof id === 'number'){
        id = id+'';
    }

    if(single){
        if(!id){
            id = "0";
        }
    }

    useFirestoreConnect(() => [
        {collection: GAMES, doc:id}
    ]);
    
    let data = useSelector(state => state.firestore.ordered[GAMES]);
    data = (data && data.length === 1) ? data[0] : data;

    const loaded = isLoaded(data);
    const empty = isEmpty(data);

    useAbortableEffect((status)=>{
        if(loaded){
            if(!status.aborted){
                onLoaded && onLoaded({
                    data:data,
                    empty:empty
                });
            }
        }
    },[data]);
    
    return {
        data:data,
        loaded:loaded,
        empty:empty
    }
}

export const useGames_Users = (id,props = {})=>{
    const {onLoaded,single = true,format = 'ordered'} = props;
    
    if(typeof id === 'number'){
        id = id+'';
    }

    if(single){
        if(!id){
            id = "0";
        }
    }

    useFirestoreConnect(() => [    
        {collection: GAMES,doc:id,subcollections:[{collection:USERS}],storeAs:`${GAMES}_${USERS}`}
    ]);
    
    let data = useSelector(state => state.firestore[format][`${GAMES}_${USERS}`]);

    const loaded = isLoaded(data);
    const empty = isEmpty(data);

    useAbortableEffect((status)=>{
        if(loaded){
            if(!status.aborted){
                onLoaded && onLoaded({
                    data:data,
                    empty:empty
                });
            }
        }
    },[data]);
    
    return {
        data:data,
        loaded:loaded,
        empty:empty
    }
}

export const useGames_RoundScores = (id,props = {})=>{
    const {onLoaded,single = true,format = 'data'} = props;
    
    if(typeof id === 'number'){
        id = id+'';
    }

    if(single){
        if(!id){
            id = "0";
        }
    }

    useFirestoreConnect(() => [    
        {collection: GAMES,doc:id,subcollections:[{collection:ROUNDSCORES}],storeAs:`${GAMES}_${ROUNDSCORES}`}
    ]);
    
    let data = useSelector(state => state.firestore[format][`${GAMES}_${ROUNDSCORES}`]);

    const loaded = isLoaded(data);
    const empty = isEmpty(data);

    useAbortableEffect((status)=>{
        if(loaded){
            if(!status.aborted){
                onLoaded && onLoaded({
                    data:data,
                    empty:empty
                });
            }
        }
    },[data]);
    
    return {
        data:data,
        loaded:loaded,
        empty:empty
    }
}

export const useGames_rawRoundScores = (id,props = {})=>{
    const {onLoaded,single = true,format = 'data'} = props;
    
    if(typeof id === 'number'){
        id = id+'';
    }

    if(single){
        if(!id){
            id = "0";
        }
    }

    useFirestoreConnect(() => [    
        {collection: GAMES,doc:id,subcollections:[{collection:RAWROUNDSCORES}],storeAs:`${GAMES}_${RAWROUNDSCORES}`}
    ]);
    
    let data = useSelector(state => state.firestore[format][`${GAMES}_${RAWROUNDSCORES}`]);

    const loaded = isLoaded(data);
    const empty = isEmpty(data);

    useAbortableEffect((status)=>{
        if(loaded){
            if(!status.aborted){
                onLoaded && onLoaded({
                    data:data,
                    empty:empty
                });
            }
        }
    },[data]);
    
    return {
        data:data,
        loaded:loaded,
        empty:empty
    }
}

export const useDecks = (id,props = {})=>{
    const {onLoaded, single = true} = props;

    if(typeof id === 'number'){
        id = id+'';
    }

    if(single){
        if(!id){
            id = "0";
        }
    }

    useFirestoreConnect(() => [
        {collection: DECKS, doc:id}
    ]);
    
    let data = useSelector(state => state.firestore.ordered[DECKS]);
    data = (data && data.length === 1) ? data[0] : data;

    const loaded = isLoaded(data);
    const empty = isEmpty(data);

    useAbortableEffect((status)=>{
        if(loaded){
            if(!status.aborted){
                onLoaded && onLoaded({
                    data:data,
                    empty:empty
                });
            }
        }
    },[data]);
    
    return {
        data:data,
        loaded:loaded,
        empty:empty
    }
}

export const useTimers = (id,props = {})=>{
    const {onLoaded, single = true,format = 'data'} = props;

    if(typeof id === 'number'){
        id = id+'';
    }

    if(single){
        if(!id){
            id = "0";
        }
    }

    useFirestoreConnect(() => [
        {collection: TIMERS, doc:id}
    ]);
    
    let data = useSelector(state => state.firestore[format][TIMERS]);
    data = (data && data.length === 1) ? data[0] : data;

    const loaded = isLoaded(data);
    const empty = isEmpty(data);

    useAbortableEffect((status)=>{
        if(loaded){
            if(!status.aborted){
                onLoaded && onLoaded({
                    data:data,
                    empty:empty
                });
            }
        }
    },[data]);
    
    return {
        data:data,
        loaded:loaded,
        empty:empty
    }
}
