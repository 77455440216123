import React from "react";
import {useDispatch,useSelector} from "react-redux";
import {app} from "../actions";
import {View} from "../utilities";
import {useLocale} from "../hooks";

import EN from "./en.js";
import ID from "./id.js";
import Select from "../components/Select";
import Field from "../components/Field";

const availableOptions = {
  en: EN,
  id: ID
};

export const getTranslate = optionKey => key => {
  if(key === undefined){
    return availableOptions[optionKey];
  }
  return availableOptions[optionKey][key] || key;
}

export const LanguageComponent = props => {
    const dispatch = useDispatch();
    const locale = useLocale();
    const current = useSelector(state=>state.app.language);

    const onChange = e => dispatch(app.setLanguage(e.target.value));
    
    return (
      <Field label={locale.settings.selectLanguage}>
        <Select
          name="language"
          value={current}
          onChange={onChange}
          options={availableOptions}
        />
      </Field>
    );
};
