export default {
    getName: () => 'English',
    app: {
        title: 'Least Count',
        subtitle: 'Play with upto 8 player online',
        confirmationText: 'Are you sure?',
        yes: 'Yes',
        no: 'No',
    },
    landing: {
        welcome: 'Welcome',
        create: 'Create Room',
        join: 'Join a room',
    },
    create: {
        avatar: 'Pick Avatar',
        name: 'Your Name',
        captcha: 'Captcha',
        submit: 'Create',
        required: 'Is Required',
        wrong: 'Is Wrong',
        notExists: 'Not Exists',
    },
    join: {
        room: 'Room ID',
        submit: 'Join',
    },
    lobby: {
        start: 'Start',
        random: 'Match with random players',
        copy: 'Copy Room Link',
        player: 'Players',
        leave: 'Leave',
        confirmLeave: 'Are you sure leaving the match?',
    },
    captcha: {
        notSupported: 'Not supported in your current browser.',
        help: 'Please type the characters you see in the captcha image next to this. Uppercase and lowercase letters are not distinguished !',
    },
    game: {
        move: 'MOVE',
        show: 'SHOW',
        result: {
            point: 'Points',
        },
        resultAction: {
            spectate: 'Spectate',
            fortfeit: 'Fortfeit',
            main: 'Main Menu',
            nextRound: 'Play Round',
            forfeited: 'You\'ve forfeited this match',
            kicked: 'You\'ve been kicked out of this match',
        },
        info: {
            initial: 'Preparing...',
            start: 'It\'s Your Turn',
            opStart: '{name}\'s Turn',
            show: '{name}\'s Show',
            timeout: '{name}\'s Timeout',
            endRound: 'Round is ended',
            endGame: 'Game is ended. You will be redirect to lobby in {timer} seconds',
            droppedNotSame: 'Please take one card from the card on the table',
            droppedStill: 'The card you hold still has the same card that you just put',
            droppedSame: 'Please press the move button or take one of the cards on the table',
            picked: 'Please press the move button',
            switchTurn: 'Switch Turn',
            resultWin: 'You Win',
            resultLose: 'You Lose',
            resultEndRound: 'Round {round} ends',
        },
    },
    deck: {
        reqMinimum: 'Minimun Players is between 2 and 8',
    },
    settings: {
        selectLanguage: 'Select language',
        selectTheme: 'Select theme',
        gameSettings: 'Game Settings',
    },
}
