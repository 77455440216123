import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore,applyMiddleware,compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance,reduxFirestore, getFirestore } from 'redux-firestore';
import { ToastProvider } from 'react-toast-notifications';
import reducers from './reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from './themes';
import { ModalComponent } from './modals';
import firebase from './firebase';

const middlewares = [thunkMiddleware.withExtraArgument({getFirestore})];
const middlewareEnhancer = applyMiddleware(...middlewares);
const rfConfig = {};
const store = createStore(
  reducers,
  compose(
    middlewareEnhancer,
    reduxFirestore(firebase),
  ) 
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={rfConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <ThemeProvider>
          <ToastProvider 
            autoDismiss={true}
            autoDismissTimeout={5000}
          >
            <App />
            <ModalComponent id="global" />
          </ToastProvider>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
