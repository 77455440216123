export default {
    getName: () => 'Light',
    primary: '#4E7CFF',
    secondary: '#B57EDC',
    tertiary: '#EEF2FF',
    body: {
        backgroundColor: 'white',
        color: '#000000',
    },
    button: {
        color: 'white'
    },
    avatar: {
        backgroundColor: '#4E7CFF',
        fill: 'white',
        color: '#000000'
    },
    gear: {
        backgroundColor: 'transparent',
        fill: '#659b5e',
        color: '#659b5e'
    },
    card: {
        host: {
            backgroundColor: '#A0B8FF'
        },
        draggableArea: {
            borderColor: '#577de4',
            backgroundColor: '#e0e7fa',
            backgroundColorActive: '#A0B8FF',
        },
    },
    input: {
        backgroundColor: 'white',
        color: '#000000'
    },
    captchaBackground: '#9CB6FF',
    captchaFill: 'white',
    captchaImage: {
        rectangle: '#282f48',
        triangle: '#8696d5',
        text: '#fff'
    },
    scrollbarForeground:  '#9cb6ff',
    scrollbarBackground:  '#eef2ff',
    modalBodyBackground:  '#4c76ed',
    modalBodyBackgroundSecondary:  '#ffffff',
    modalBodyColor:  '#ffffff',
    modalBodyColorSecondary:  '#000000',
    highlightBackground:  'linear-gradient(270deg, rgba(78, 124, 255, 0.5) 0%, #4e7cff 48%, rgba(78, 124, 255, 0.5) 100%)',
    highlightColor:  '#ffffff',
    highlightBackgroundSecondary: 'linear-gradient(270deg, rgba(78, 124, 255, 0.5) 0%, #4e7cff 48%, rgba(78, 124, 255, 0.5) 100%)',
}
