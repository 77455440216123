import {setFailure,setSuccess,setRequest} from './app';

export const create = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
  indicator && dispatch(setRequest());
  const db = getFirestore();
  try{
      const {gameid,decks} = params;
      const docRef = db.collection("decks").doc(gameid);
      await docRef.set(decks);

      dispatch(setSuccess());
  }catch(err){
      dispatch(setFailure(err.message));
      throw err;
  }
}

export const update = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
    indicator && dispatch(setRequest());
    const db = getFirestore();
    try{
        const {id,fields} = params;
        const docRef = db.collection("decks").doc(id);        
        await docRef.update(fields);
  
        dispatch(setSuccess());
    }catch(err){
        dispatch(setFailure(err.message));
        throw err;
    }
}