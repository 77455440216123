import React from 'react';
import styled from 'styled-components';
import ConfirmAction from '../ConfirmAction';
import { useLocale } from '../../../hooks';
import AppHeader from '../../../components/AppHeader';

const ConfirmContainer = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: ${props => props.theme.modalBodyBackgroundSecondary};
  color: ${props => props.theme.modalBodyColorSecondary};
  border-radius: 5px;
  font-size: 1.2em;
  text-align:center;
  padding: 20px;
`;

const Confirm = ({ onConfirm, description })=>{
    const locale = useLocale();
    
    return (
        <ConfirmContainer>
            <AppHeader subtitle={description || locale.app.confirmationText} />
            <ConfirmAction
              onConfirm={onConfirm}
            />
        </ConfirmContainer>
    )
}

export default Confirm;
