import {useSelector} from "react-redux";
import {getTranslate} from '../locales';
import {getTheme} from '../themes';
import {useState,useRef,useEffect} from 'react';

export function useLocale(key){
    const current = useSelector(state=>state.app.language);
    return {locale:current,...getTranslate(current)(key)}
}

export function useTheme(key){
    const current = useSelector(state=>state.app.theme);
    return {theme:current,...getTheme(current)(key)}
}

export function useForceUpdate(){
    let [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

export function useModal(modal) {
    const current = useSelector(state=>state.app.modalOpened);
    return {modalOpened: current};
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect((status) => {
        ref.current = value;
    });
    return ref.current;
}

export function useIsFsRequesting(){
    const requesting = useSelector(state=>{
        const tmp = state.firestore.status.requesting;
        for(let key in tmp){
            if(tmp[key]){
                return true;
            }
        }
        return false;
    });

    const loading = useSelector(state=>state.app.loading);
    return requesting || loading;
}

//Untuk menghindari warning Can't perform a React state update on an unmounted component
export const useAbortableEffect = (effect, dependencies)=>{
    const status = {}; // mutable status object
    useEffect(() => {
        status.aborted = false;
        // pass the mutable object to the effect callback
        // store the returned value for cleanup
        const cleanUpFn = effect(status);
        return () => {
        // mutate the object to signal the consumer
        // this effect is cleaning up
        status.aborted = true;
        if (typeof cleanUpFn === "function") {
            // run the cleanup function
            cleanUpFn();
        }
        };
    }, [...dependencies]);
}

export function useCaptcha() {
    const captcha = useSelector(state=>state.app.captcha);
    return {captcha};
}
