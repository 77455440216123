export default {
    getName: () => 'Indonesian',
    app: {
        title: 'Hitungan Terakhir',
        subtitle: 'Bermain dengan hingga 8 pemain online',
        confirmationText: 'Kamu yakin?',
        yes: 'Ya',
        no: 'Tidak',
    },
    landing: {
        welcome: 'Selamat Datang',
        create: 'Buat Ruang',
        join: 'Gabung Ruang',
    },
    create: {
        avatar: 'Pilih Avatar',
        name: 'Nama',
        captcha: 'Captcha',
        submit: 'Buat',
        required: 'Wajib Diisi',
        wrong: 'Salah Diisi',
        notExists: 'Tidak ditemukan',
    },
    join: {
        room: 'ID Ruangan',
        submit: 'Gabung',
    },
    lobby: {
        start: 'Mulai',
        random: 'Cari Pemain Secara Acak',
        copy: 'Salin ID Ruangan',
        player: 'Pemain',
        leave: 'Pergi',
        confirmLeave: 'Yakin ingin meninggalkan permainan?',
    },
    captcha: {
        notSupported: 'Tidak di dukung oleh browser anda.',
        help: 'Silakan ketikkan karakter yang anda lihat pada gambar captcha di samping ini. Huruf besar dan kecil tidak dibedakan !',
    },
    game: {
        move: 'PINDAH',
        show: 'BUKA KARTU',
        result: {
            point: 'Poin',
        },
        resultAction: {
            spectate: 'Spectate',
            fortfeit: 'Menyerah',
            main: 'Halaman Utama',
            nextRound: 'Lanjut Ronda ke ',
            forfeited: 'Anda telah mengabaikan permainan ini',
            kicked: 'Anda telah dikeluarkan dari permainan ini',
        },
        info: {
            initial: 'Mempersiapkan...',
            start: 'Giliran Kamu',
            opStart: 'Giliran {name}',
            show: 'Dibuka oleh {name}',
            timeout: 'Timeout oleh {name}',
            endRound: 'Ronde Berakhir',
            endGame: 'Permainan Berakhir. Kamu akan di arahkan ke lobi dalam waktu {timer} detik',
            droppedNotSame: 'Silakan ambil satu kartu dari meja',
            droppedStill: 'Anda masih punya kartu yang sama dengan yang ditaruh di meja',
            droppedSame: 'Silakan tekan tombol pindah atau ambil satu kartu dari meja',
            picked: 'Silakan tekan tombol pindah',
            switchTurn: 'Ganti Giliran',
            resultWin: 'Kamu Menang',
            resultLose: 'Kamu Kalah',
            resultEndRound: 'Ronde {round} Berakhir',
        },
    },
    deck: {
        reqMinimum: 'Jumlah pemain minimum antara 2 dan 8',
    },
    settings: {
        selectLanguage: 'Pilih bahasa',
        selectTheme: 'Pilih tema',
        gameSettings: 'Pengaturan game',
    },
}
