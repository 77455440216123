import React,{useState} from 'react';
import Switch from "react-switch";
import {useLocale} from '../../hooks';
import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';
import FlexContainer from '../../components/FlexContainer';
import Button from '../../components/Button';
import Avatar from '../../components/Avatar';
import Container from '../../components/Container';
import AppHeader from '../../components/AppHeader';
import ReadyIcon from './assets/ready.svg';
import ClockIcon from './assets/clock.svg';

const renderPlayer = ({data,isHost,onRemovePlayer})=>{
    const {id,name,host,isReady} = data;
    let ChildComp = '';
    if(isReady){
        ChildComp = (<img src={ReadyIcon} style={{width:'2em',position:'relative',bottom:'2.5px'}} alt="ready"/>);
    }else{
        ChildComp = (<img src={ClockIcon} style={{width:'1.25em',marginRight:'0.3em',position:'relative',bottom:'2px'}} alt="waiting"/>);
    }

    return (
        <FlexContainer padding="0.5em" key={id} height="auto" flexDirection="row" justifyContent="left">
            <Avatar margin="0" size="small" label={name}/>
            {ChildComp}
        </FlexContainer>
    );
}

const renderPlayers = ({data,isHost,onRemovePlayer})=>{
    return data ? data.map((player)=>{
        return renderPlayer({data:player,isHost:isHost,onRemovePlayer:onRemovePlayer})
    }) : []
}

const SidebarContainer = styled.div`
    height: ${props => props.height}px;
    display: flex;
    flex-direction: column;
    padding: 2em;
`

const LobbySidebar = ({isHost,onRandom,onCopyLink,onRemovePlayer,data = {
    players:[],
    roomID:"-"
}})=>{
    const locale = useLocale();
    const fullHeight = use100vh();
    let [random,setRandom] = useState(false);

    return (
        <SidebarContainer height={fullHeight}>
            <AppHeader container={{textAlign:'left',margin:"0 0 1em 0",letterSpacing:0}} title={{fontSize:"1.5em",content:locale.app.title,margin:"0 0 0.25em 0"}} subtitle={{content:`${locale.join.room} : ${data.roomID}`,fontSize:"0.75em",fontWeight:'bold'}}/>
            <div style={{ flex: 1, display: 'flex' }}>
                <Container style={{ height: '100%', display: 'flex' }} padding="1em">
                    <FlexContainer height="100%%" justifyContent="space-between">
                        <div style={{alignSelf:'normal'}}>
                            <label style={{textAlign:"center",margin:"0 0 1em 0",fontWeight:"bold",display:"block"}}>{locale.lobby.player} ({data.players ? data.players.length : 0})</label>
                            <div style={{alignSelf:'normal'}}>
                                {renderPlayers({data:data.players,isHost:isHost,onRemovePlayer:onRemovePlayer})}
                            </div>
                        </div>
                        <div style={{alignSelf:'normal'}}>
                            <FlexContainer margin="0 0 0.5em 0" padding="0.5em" height="auto" flexDirection="row" justifyContent="space-between">
                                <label style={{fontSize:"0.75em"}}>{locale.lobby.random}</label>
                                <Switch
                                  checked={random}
                                  onChange={e=>{setRandom(e);onRandom(e)}}
                                  onColor="#9eb6ff"
                                  onHandleColor="#4e7cff"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                />
                            </FlexContainer>
                            <div style={{textAlign:'center'}}>
                                <Button fontSize="0.75em" onClick={onCopyLink}>{locale.lobby.copy}</Button>
                            </div>
                        </div>
                    </FlexContainer>
                </Container>
            </div>
        </SidebarContainer>
    );
}

export default LobbySidebar;
