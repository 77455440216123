import React from 'react';
import { OpponentCard} from '../shares/Card';
import {useLocale} from '../../../hooks';
import Container from '../../../components/Container';
import AppHeader from '../../../components/AppHeader';
import Avatar from '../../../components/Avatar';
import FlexContainer from '../../../components/FlexContainer';

export default ({ 
  players, 
  hostName, 
  highlight, 
  cardAnimation,
  totalHolding = {},
  roomCode
}) => {
    const locale = useLocale();

    const renderPlayers = ()=>{
        if([7,6,5].includes(players.length)){
            return (
                <>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentLeftTop" cardAnimation={cardAnimation} user={players[1]} styleGrid={{margin:'1em 0 2em 0'}}/>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentLeftBottom" cardAnimation={cardAnimation} user={players[0]}/>
                </>
            );
        }else if([4,3].includes(players.length)){
            return (
                <>
                <OpponentCard totalHolding={totalHolding} cardAnimationType="opponentLeftMiddle" cardAnimation={cardAnimation} user={players[0]}/>
                </>
            );
        }
    }
    
    return (
        <FlexContainer justifyContent="space-between">
            <AppHeader container={{textAlign:'left',margin:".75em 0 0",letterSpacing:0}} title={{fontSize:"1.5em",content:locale.app.title,margin:"0 0 0.25em 0"}} subtitle={{content:`Room ID : ${roomCode}`,fontSize:"0.75em",fontWeight:'bold'}}/>
            <Container padding="1em 0 0 0"  backgroundColor='none' minWidth="160px">
                <FlexContainer height="auto" >
                    <FlexContainer flex="0 1 0" justifyContent="start">
                        {renderPlayers()}
                    </FlexContainer>
                </FlexContainer>
            </Container>
            <div>
              <FlexContainer height="136px">
                <Avatar size="large" highlight={highlight}/>
                <label style={{textAlign:"center",display:"block",marginBottom:".75em"}}>{hostName}</label>
              </FlexContainer>
            </div>
        </FlexContainer>
    );
}
