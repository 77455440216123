import React from 'react';
import styled from 'styled-components';
import {getThemeColor} from '../../themes';

const Button = styled.button`
    border-radius:${props => props.borderRadius};
    padding:${props => props.padding};
    font-weight:${props => props.fontWeight};
    border:${props => props.border};
    cursor:${props => props.cursor};
    background-color:${props => {
            if(props.disabled){
                return 'grey';
            }
            return props.backgroundColor ? getThemeColor(props.backgroundColor,props.theme) : props.theme.primary
        }
    };
    color:${props=>props.color ? props.color : props.theme.button.color};
    margin-left:${props=>props.marginLeft};
    margin-right:${props=>props.marginRight};
    align-self:${props => props.alignSelf};
    font-size:${props => props.fontSize};
    width:${props=>props.width};   
    border:1px solid transparent;
    ${props => !props.disabled && `&:hover{
        color:${props => props.backgroundColor ? getThemeColor(props.backgroundColor,props.theme) : props.theme.primary};
        background-color:${props => props.color ? props.color : props.theme.button.color};
        border:1px solid ${props => props.backgroundColor ? getThemeColor(props.backgroundColor,props.theme) : props.theme.primary};
    }`}
    pointer-events:${props=>props.disabled ? 'none' : 'auto'}
`;

export default (_props)=>{
    /*eslint-disable no-undef*/
    const props = {
        onClick,
        children,
        borderRadius = '0.375em',
        padding = '0.75em',
        fontWeight = 'bold',
        border = 'none',
        cursor = 'pointer',
        backgroundColor = '',
        color = '',
        marginLeft = '0.5em',
        marginRight = '0.5em',
        alignSelf = 'auto',
        fontSize = '1em',
        width = 'auto',
        style = {},
        disabled = false
    } = _props;
    /*eslint-enable no-undef*/

    return (
        <Button {...props} style={{...props.style}}>
            {props.children}
        </Button>
    )
}
