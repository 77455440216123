import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
    letter-spacing:${props => props.letterSpacing};
    text-align:${props => props.textAlign};
    margin:${props => props.margin};
`;

const Title = styled.p`
    font-weight:${props => props.fontWeight};
    font-size:${props => props.fontSize};
    margin:${props => props.margin};
    color:${props => props.color};
`;

const Subtitle = styled.p`
    margin:${props => props.margin};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
`;

export default props => {
    const {
        title,
        subtitle,
        container,
        isEnd,
        isWin,
    } = props;
    const titleProps = {
        fontWeight: 'bold',
        fontSize: '2em',
        margin: 0,
        color: isEnd ? (isWin ? '#659b5e' : '#e88176') : 'inherit',
    };
    const subtitleProps = {
        fontWeight: 'normal',
        fontSize: '1em',
        margin: 0,
    };
    const containerProps = {
        letterSpacing: '0.1em',
        textAlign: 'center',
        margin: (container || {}).margin || '2em',
    };

    if(title){
        const withContent = typeof title.content === 'string';
        titleProps.content = withContent ? title.content : title;
    }

    if(subtitle){
        const withContent = typeof subtitle.content === 'string';
        subtitleProps.content = withContent ? subtitle.content : subtitle;
    }

    if(typeof container === 'string'){
        throw new Error('invalid div properties');
    }
    
    return (
        <Div {...containerProps}>
            <Title {...titleProps}>{titleProps.content}</Title>
            {subtitleProps.content && <Subtitle {...subtitleProps}>{subtitleProps.content}</Subtitle>}
        </Div>
    )
}
