export default function deepCopy (obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  let temporaryStorage = obj.constructor()

  for (let key in obj) {
    temporaryStorage[key] = deepCopy((obj[key]))
  }
  return temporaryStorage
}
