import React from 'react';

export const renderOptions = (objects)=>{
    return Object.keys(objects).map((key)=>(
        <option value={key} key={key}>
          {objects[key].getName()}
        </option>
    ));
}

export const getSizeCss = (size,unit = "em")=>{
  if(size === 'small'){
    size = 2;
  }else if (size === 'medium'){
    size = 3;
  }else if(size === 'large'){
    size = 4;
  }else{
    size = parseInt(size);
  }

  return {
    borderRadius:`${size}${unit}`,
    width:`${size}${unit}`,
    height:`${size}${unit}`
  }
}