import React,{ useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { user } from '../actions';
import { 
    useLocale,
    useTheme,
    useIsFsRequesting,
    useAbortableEffect,
    useCaptcha,
} from '../hooks';
import { useUsers } from '../hooks/collection';
import FlexContainer from '../components/FlexContainer';
import AppHeader from '../components/AppHeader';
import Button from '../components/Button';
import Container from '../components/Container';
import Avatar from '../components/Avatar';
import Input from '../components/Input';
import Captcha from '../components/Captcha';
import Field from '../components/Field';
import Loader from '../components/Loader';

export default props => {
    const {history} = props;
    const {
        data:dataUser,
        empty:isDataUserEmpty,
    } = useUsers(user.get(),{
        onLoaded: resp => {
            if(!resp.empty && resp.data.currentGame){
                history.replace('/lobby');
            }
        }
    });
    
    useAbortableEffect((status)=>{
        if(!status.aborted && dataUser){
            setUsername(dataUser.name);
        }
    },[dataUser]);

    const locale = useLocale();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { captcha: captchaValue } = useCaptcha();
    const [username,setUsername] = useState('');
    const [captcha,setCaptcha] = useState('');
    const loading = useIsFsRequesting();
    
    const toast = (content, type) => addToast(content, {
        appearance: type,
        autoDismiss: true,
    });

    const validateAll = () => {
        const usernameValid = username && username.trim().length;
        const captchaValid = captcha && captcha.trim().length;
        const captchaMatched = captchaValue.toLowerCase() === captcha.toLowerCase();
        if(!usernameValid) toast(`${locale.create.name} ${locale.create.required}`, 'error');
        if(!captchaValid) toast(`${locale.create.captcha} ${locale.create.required}`, 'error');
        if(captchaValid && !captchaMatched) toast(`${locale.create.captcha} ${locale.create.wrong}`, 'error');
        return usernameValid && captchaValid && captchaMatched;
    };

    const onCreate = () => {
        if(validateAll()){
            dispatch(user.createRoom({
                params:{
                    user:isDataUserEmpty ? false : dataUser,
                    username:username.trim(),
                    currentGame:'',
                    theme:theme.theme,
                    language:locale.locale
                }
            })).catch(err => {
                toast(err.message, 'error');
            });
        }
    }

    return (
        <Loader loading={loading}>
            <FlexContainer height={95}>
                <AppHeader title={locale.app.title} subtitle={locale.app.subtitle}/>

                <Container maxWidth="360px" minWidth="360px">
                    <FlexContainer height="auto" alignItems="left" padding="1.5rem 3rem">
                        <Field label={locale.create.avatar}>
                            <Avatar size={6}/>
                        </Field>
                        <Field label={locale.create.name}>
                            <Input type="text" name="name" value={username} onChange={e=>setUsername(e.target.value)}/>
                        </Field>
                        <Field label={locale.create.captcha}>
                            <Captcha type="text" name="captcha" value={captcha} onChange={e=>setCaptcha(e.target.value)} />
                        </Field>
                        <Field container={{textAlign:'center',margin:'1.5em 0 0 0'}}>
                            <Button width={"8em"} alignSelf="center" onClick={onCreate}>{locale.create.submit}</Button>
                        </Field>
                    </FlexContainer>
                </Container>
            </FlexContainer>
        </Loader>
    );
}
