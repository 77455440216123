import React from 'react';
import { useDispatch } from 'react-redux';
import FlexContainer from '../../../components/FlexContainer';
import Button from '../../../components/Button';
import { setModalOpened } from '../../../actions/app';
import { useLocale } from '../../../hooks';

const ConfirmAction = props => {
    const {
        onConfirm,
    } = props;
    const dispatch = useDispatch();
    const closeModal = () => dispatch(setModalOpened(false));
    const locale = useLocale('app');
    
    const doConfirm = () => {
        onConfirm && onConfirm();
        closeModal();
    }
    
    return (
        <div css={`margin:16px;`}>
            <FlexContainer height="auto" flexDirection="row">
                <>
                    <Button onClick={closeModal}>{locale.no}</Button>
                    <Button onClick={doConfirm}>{locale.yes}</Button>
                </>
            </FlexContainer>
        </div>
    )
}

export default ConfirmAction;
