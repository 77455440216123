import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Leaderboard from './contents/Leaderboard';
import Result from './contents/Result';
import Forfeit from './contents/Forfeit';
import Confirm from './contents/Confirm';
import Settings from './contents/Settings';
import { ReactComponent as CloseSvg } from './assets/close.svg';
import { setModalOpened } from '../actions/app';
import { useLocale } from '../hooks';

const Div = styled.div`
    position: fixed;
    z-index: 500;
    width: auto;
    max-width: 80%;
    left: 50%;
    top: 50%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    transform: translate3d(-50%, -50%, 0);
`;

const BackdropDiv = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.85);
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  & svg{
    width:30px;
    height:30px;
  }
`;

const Backdrop = props => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(setModalOpened(false));
  return props.show ? <BackdropDiv onClick={closeModal} /> : null
}

const CloseButton = props => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(setModalOpened(false));
  return props.show ? (
    <CloseDiv onClick={closeModal}>
      <CloseSvg />
    </CloseDiv>
  ) : null;
}

export const ModalComponent = props => {
  const {
    modalOpened,
    modalId,
  } = useSelector( state => state.app );
  const { 
    id,
    result,
    leaderboard,
    onNextRound,
    onForfeit,
    onSpectate,
    onMainMenu,
    onLeave,
  } = props;
  const locale = useLocale();
  let modal;
  let closeButton;

  switch (modalOpened) {
    case 'leaderBoard':
      modal = <Leaderboard {...leaderboard} />;
      closeButton = true;
      break;

    case 'gameResult':
      modal = (
        <Result
          {...result}
          onForfeit={onForfeit}
          onNextRound={onNextRound}
          onSpectate={onSpectate}
          onMainMenu={onMainMenu}
        />
      );
      closeButton = false;
      break;

    case 'forfeit':
      modal = (
        <Forfeit
          onSpectate={onSpectate}
          onMainMenu={onMainMenu}
        />
      );
      closeButton = false;
      break;

    case 'confirmLeave':
      modal = (
        <Confirm
          onConfirm={onLeave}
          description={locale.lobby.confirmLeave}
        />
      );
      closeButton = false;
      break;

    case 'settings':
      modal = (
        <Settings />
      );
      closeButton = false;
      break;

    default:
      modal = false;
      closeButton = false;
      break;
  }

  return (
    modal && modalId === id ?
      <>
        <Backdrop show={modalOpened} />
        <Div>
          { modal }
          <CloseButton show={closeButton} />
        </Div>
      </> : null
  );
};
