import React from 'react';
import styled from 'styled-components';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import {isSafari} from 'react-device-detect';
import SingleCard, {getMultiplierSize} from './SingleCard';
import OpenCard from './OpenCard';
import {DA_CLOSED_STACK, DA_CURRENT_PLAYER, ID_DA_STACK} from './constant';

const Grid = styled.div`
    display: grid;
    margin:auto;
    position:relative;
    right:8px;
    grid-template-columns: repeat(auto-fill,  minmax(${props => getMultiplierSize(props.distance ? props.distance : props.size, 8) + "px"}, max-content));
`

const renderCard = (props) => {
  let cards = [];

  if (props.data && Object.keys(props.data).length) {
    cards.push(<OpenCard
      cardAnimation={props.cardAnimation}
      cardAnimationType="wildCard"
      index={-1}
      key="-1"
      data={props.data}
      iconAlignment="bottom"
      addAnimation={true}
      margin="0 -20px"
    />);
  }

  const stackCardProps = {
    ...props,
    manualClick: ({draggableId, sourceId}) => {
      props.onDragEnd({
        source: {
          droppableId: DA_CLOSED_STACK,
          index: sourceId,
        },
        destination: {
          droppableId: DA_CURRENT_PLAYER,
          index: 0,
        },
        draggableId
      })
    }
  }

  for (let i = 0; i < props.total; i++) {
    const isLast = i === props.total - 1;
    if (props.draggable && isLast) {
      cards.push(
        isSafari ? (
          <div key={i} onClick={() => stackCardProps.manualClick({
            draggableId: ID_DA_STACK,
            sourceId: i
          })}>
            <SingleCard {...props}/>
          </div>
        ) : (
          <Draggable key={i} draggableId={ID_DA_STACK} index={i}>
            {provided => {
              return (
                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}
                     style={{width: '96px', height: '136px', ...provided.draggableProps.style}}>
                  <SingleCard {...props}/>
                </div>
              )
            }}

          </Draggable>
        )
      );
    } else {
      cards.push((<SingleCard islast={isLast ? 1 : 0} index={i} key={i} {...props}/>));
    }
  }

  return cards;
}

export default (_props) => {
  /*eslint-disable no-undef*/
  let props = {
    size = 'medium',
    total = 3,
    distance = '',
    data = {},
    user = {},
    animation = 'none',
    cardAnimation = 'none',
    cardAnimationType = 'opponentTopCenter',
    draggable = false,
    onDragEnd = null
  } = _props;
  /*eslint-enable no-undef*/

  const render = (placeholder) => (
    <Grid {...props}>
      {renderCard(props)}
      {placeholder}
    </Grid>
  )


  if (props.draggable) {
    return (
      <Droppable droppableId={DA_CLOSED_STACK} isDropDisabled={true} type={DA_CURRENT_PLAYER} direction="horizontal">
        {(provided) => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {render(provided.placeholder)}
            </div>
          )
        }}
      </Droppable>
    )
  }

  return render();
}
