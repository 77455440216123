import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './pages/Landing';
import CreatePage from './pages/Create';
import JoinPage from './pages/Join';
import LobbyPage from './pages/Lobby';
import GamePage from './pages/Game';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/create' component={CreatePage} />
        <Route exact path='/join' component={JoinPage} />
        <Route exact path='/join/:id' component={JoinPage} />
        <Route exact path='/lobby' component={LobbyPage} />
        <Route exact path='/lobby/:id' component={LobbyPage} />
        <Route exact path='/game' component={GamePage} />
      </Switch>
    </Router>
  );
}

export default App;
