import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    useLocale,
    usePrevious,
    useAbortableEffect,
} from '../../hooks';
import {
    useUsers,
    useGames,
    useGames_Users,
    useGames_RoundScores,
    useGames_rawRoundScores,
    useDecks,
} from '../../hooks/collection';
import {
    useNetworkConnectivity,
} from '../../hooks/network';
import {
    useForceUpdate,
} from '../../hooks/forceUpdate';
import {
    user,
    game,
    deck,
    timer,
} from '../../actions';
import { setModalOpened } from '../../actions/app';
import { Game } from '../../utilities';
import { ModalComponent } from '../../modals';
import FlexContainer from '../../components/FlexContainer';
import Loader from '../../components/Loader';
import Holding from './shares/Holding';
import { DA_OPEN_STACK, DA_CURRENT_PLAYER, DA_CLOSED_STACK } from './shares/Card/constant';
import GameSidebarLeft from './parts/GameSidebarLeft';
import GameSidebarRight from './parts/GameSidebarRight';
import Main from './parts/Main';
import {useWindowSize} from '../../../src/hooks/windowSize';

export default props => {
    const { history } = props;
    const {
        data: dataUser,
        loaded: dataUserLoaded,
    } = useUsers(user.get(),{
        onLoaded:(resp) => {
            if(resp.empty){
                history.replace('/create');
            }
        }
    });
    const {
        data: dataGame,
        loaded: dataGameLoaded,
    } = useGames(dataUser ? dataUser.currentGame : 0);
    const {
        data: dataGameUsers,
        loaded: dataGameUsersLoaded,
    } = useGames_Users(dataUser ? dataUser.currentGame : 0);
    const {
        data: dataGameRoundScores,
        loaded: dataGameRoundScoresLoaded,
    } = useGames_RoundScores(dataUser ? dataUser.currentGame : 0);
    const {
        data: dataGameRawRoundScores,
        loaded: dataGameRawRoundScoresLoaded,
    } = useGames_rawRoundScores(dataUser ? dataUser.currentGame : 0);
    const {
        data: dataDecks,
        loaded: dataDecksLoaded,
    } = useDecks(dataUser ? dataUser.currentGame : 0,{
        onLoaded:(resp) => {
            if(!resp.empty){
                setCacheDeck(resp.data);
            }
        }
    });

    const dispatch = useDispatch();
    const locale = useLocale('game');
    const [ moveBtnDisabled,setMoveBtnDisabled ] = useState(true);
    const [ showBtnDisabled,setShowBtnDisabled ] = useState(true);
    const [ playerCanPick,setPlayerCanPick ] = useState(false);
    const [ isChainable,setChainable ] = useState(false);
    const [ isDraggable,setDraggable ] = useState(false);
    const [ animation,setAnimation ] = useState('');
    const [ cardAnimation, setCardAnimation ] = useState('none');
    const [ result,setResult ] = useState({});
    const [ leaderboard,setLeaderboard ] = useState({});
    const [ isTimerOn,setIsTimerOn ] = useState(false);
    const [ LC,setLC ] = useState(null);
    const [ lifecycleStatus,setLifecycleStatus ] = useState(null);
    const [ dataLoaded,setDataLoaded ] = useState(false);
    const [ scheduleTimeout,setScheduleTimeout ] = useState();
    const [ cacheDeck,setCacheDeck ] = useState({});
    const [ lifecycleHistory, setLifecycleHistory ] = useState(null);
    const [ onlineStatus, setOnlineStatus ] = useState(null);
    const prevLCStatus = usePrevious(lifecycleStatus);
    const timeouts = {};
    const networkOnline = useNetworkConnectivity();
    const forceUpdate = useForceUpdate();
    const { height: windowHeight } = useWindowSize();

    /* HOOKS */
    useAbortableEffect((status) => {
        if(!status.aborted){
            setDataLoaded(
              dataUserLoaded 
              && dataGameLoaded 
              && dataGameUsersLoaded 
              && dataGameRoundScoresLoaded 
              && dataGameRawRoundScoresLoaded
              && dataDecksLoaded
            );
        }
    },[
        dataUserLoaded,
        dataGameLoaded,
        dataGameUsersLoaded,
        dataGameRoundScoresLoaded,
        dataGameRawRoundScoresLoaded,
        dataDecksLoaded,
    ]);

    useAbortableEffect(status => {
        if(!status.aborted){
            if(dataLoaded){
                setLC(Game.init(
                  dataUser,
                  dataGame,
                  dataGameUsers,
                  dataGameRoundScores,
                  dataGameRawRoundScores,
                  dataDecks,
                  locale.info,
                ));
            }
        }
    },[
        dataLoaded,
        dataUser,
        dataGame,
        dataGameUsers,
        dataGameRoundScores,
        dataGameRawRoundScores,
        dataDecks,
        locale.locale,
    ]);

    useAbortableEffect((status) => {
        if(!status.aborted && dataGame){
            const { status } = dataGame;
            const cleanState = !lifecycleHistory || (lifecycleHistory && status !== lifecycleHistory);
            if(dataLoaded && cleanState){
                setLifecycleHistory(prevLCStatus);
                setLifecycleStatus(status);
            }
        }
    },[ dataGame, dataLoaded ]);

    useAbortableEffect((status) => {
        if(!status.aborted) {
            const isOnline = onlineStatus;
            if(isOnline !== networkOnline) {
                setOnlineStatus(networkOnline);
                if(isOnline === false) {
                    forceUpdate();
                }
            }
        }
    },[ networkOnline ]);

    /**Lifecycle - All data Loaded */
    useAbortableEffect((status) => {
        const remoteAnimate = (animation, nextAnimation, finishAnimation) => {
            setCardAnimation(animation);
            if(nextAnimation && finishAnimation) {
                timeouts.cardAnimation = setTimeout(() => {
                    setCardAnimation(nextAnimation);
                }, 700);
                timeouts.cardAnimation = setTimeout(() => {
                    setCardAnimation(finishAnimation);
                }, 850);
            }
        }
        if(!status.aborted){
            dispatch(setModalOpened(false));
            clearTimeout(scheduleTimeout);

            if(lifecycleStatus === Game.STATUS.INITIAL){
                setIsTimerOn(false);
                setAnimation('');
                
                const execute = () => {
                    const update = LC.run();
                    dispatch(game.startNewRound(update)).catch(console.error);
                }

                execute();
            }else if(lifecycleStatus === Game.STATUS.START){
                setIsTimerOn(true);
                setAnimation('');

                if(LC.isTurn()){
                    changeUIState('enable');
                }else{
                    changeUIState('disable');
                }
                
                if(prevLCStatus === 'initial') {
                    setCardAnimation('initial');
                    timeouts.cardAnimation = setTimeout(() => {
                        setCardAnimation('final');
                    }, 1500);
                    timeouts.cardAnimation = setTimeout(() => {
                        setCardAnimation('none');
                    }, 1650);
                }
            }else if(lifecycleStatus === Game.STATUS.DROPPED_NOT_SAME){
                setIsTimerOn(true);
                setAnimation('');
                remoteAnimate('drop', 'droppable-in', 'finish-drop');

                if(LC.isTurn()){
                    changeUIState(Game.STATUS.DROPPED_NOT_SAME);
                }else{
                    changeUIState('disable');
                }
            }else if(lifecycleStatus === Game.STATUS.DROPPED_SAME){
                setIsTimerOn(true);
                setAnimation('');
                remoteAnimate('drop', 'droppable-in', 'finish-drop');

                if(LC.isTurn()){
                    changeUIState(Game.STATUS.DROPPED_SAME);
                }else{
                    changeUIState('disable');
                }
            }else if(lifecycleStatus === Game.STATUS.DROPPED_CHAINABLE){
                setIsTimerOn(true);
                setAnimation('');
                remoteAnimate('drop', 'droppable-in', 'finish-drop');

                if(LC.isTurn()){
                    changeUIState(Game.STATUS.DROPPED_CHAINABLE);
                }else{
                    changeUIState('disable');
                }
            }else if(
              lifecycleStatus === Game.STATUS.PICKED
              || lifecycleStatus === Game.STATUS.PICK_FROM_OPEN_DECK
            ){
                const remoteAnimation = lifecycleStatus === Game.STATUS.PICKED
                    ? 'pick-closed'
                    : 'pick-opened';
                setIsTimerOn(true);
                setAnimation('');
                remoteAnimate(remoteAnimation);

                if(LC.isTurn()){
                    changeUIState(lifecycleStatus);
                }else{
                    changeUIState('disable');
                }
            }else if(lifecycleStatus === Game.STATUS.BEFORE_COMMIT_MOVE){
                setIsTimerOn(false);
                setAnimation('AnimateBeforeCommitMove');
                setCardAnimation('before-commit-move');

                const execute = () => {
                    dispatch(timer.remove({
                        params: {
                            id: LC.Game.id,
                            key: 'turn',
                        },
                    })).then(() => {
                        dispatch(deck.update({
                            params: LC.beforeCommitMove().changes,
                        })).then(async () => {
                            if(LC.shouldCommitMoveCard()){
                                await changeStatusGame(Game.STATUS.COMMIT_MOVE);
                            }
                            console.log('main switch')
                            changeStatusGame(Game.STATUS.SWITCH_TURN);
                        }).catch(console.error);
                    }).catch(console.error);
                }

                setTimeout(() => {
                    execute();
                },Game.TIMER_ANIMATION*1000);
            }else if(lifecycleStatus === Game.STATUS.COMMIT_MOVE){
                setIsTimerOn(false);
                setAnimation('AnimateCommitMoveCard');
                setCardAnimation('none');

                const execute = () => {
                    dispatch(deck.update({
                        params:LC.commitMoveCard().changes
                    })).catch(console.error);
                }

                setTimeout(() => {
                    execute();
                },Game.TIMER_ANIMATION*1000);
            }else if(lifecycleStatus === Game.STATUS.SWITCH_TURN){
                setIsTimerOn(false);
                setAnimation('');
                setCardAnimation('none');

                const execute = async () => {
                    await dispatch(timer.create({
                        params:{
                            id:LC.Game.id,
                            init:true,
                        }
                    }));
                    dispatch(game.update({
                        params:LC.nextTurn().changes
                    }))
                }

                execute();
            }else if([Game.STATUS.SHOW,Game.STATUS.TIMEOUT].includes(lifecycleStatus)){
                setIsTimerOn(false);
                setAnimation('');

                const execute = () => {
                    const result = LC.show();
                    dispatch(game.addRoundScores({
                        params:result.roundScores
                    })).catch(console.error);
                    
                    dispatch(game.update({
                        params:result.gameScores
                    })).catch(console.error);
                }

                execute();
            }else if(lifecycleStatus === Game.STATUS.ANNOUNCE){
                if (dataGameRoundScores === null ||
                  dataGameRawRoundScores === null) {
                    return;
                }
                setIsTimerOn(false);
                setAnimation('');

                let result = LC.getResult({
                    roundScores: dataGameRoundScores,
                    rawRoundScores: dataGameRawRoundScores,
                });
                
                if(!LC.isRole('spectator')){
                    setResult(result);
                }

                const execute = () => {
                    setTimeout(() => {
                        dispatch(game.kickPlayers({
                            params:{
                                gameid:LC.Game.id,
                                kicked:result.kicked
                            }
                        })).then(() => {
                            changeStatusGame(Game.STATUS.END_ROUND);
                        }).catch(console.error);
                    },Game.TIMER_NEXT_ROUND*1000);
                }

                execute();
            }else if(lifecycleStatus === Game.STATUS.END_ROUND){
                if (dataGameRoundScores === null || 
                  dataGameRawRoundScores === null) {
                    return;
                }
                setIsTimerOn(false);
                setAnimation('');

                const result = LC.getResult({
                    roundScores: dataGameRoundScores,
                    rawRoundScores: dataGameRawRoundScores,
                });

                const execute = () => {
                    dispatch(timer.remove({
                        params:{
                            id:LC.Game.id,
                            key:"turn"
                        }
                    })).then(() => {
                        const round = parseInt(LC.Game.round);
                        const players = [...LC.getPlayers('all')].sort(a => a.host ? -1 : 0);
                        if(result){
                            dispatch(game.update({
                                params:{
                                    id:LC.Game.id,
                                    fields:{
                                        status:result.isEnd ? Game.STATUS.END_GAME : Game.STATUS.INITIAL,
                                        currentTurn:(players[round%players.length] || {}).id
                                    }
                                }
                            })).catch(console.error);
                        }else{
                            dispatch(game.update({
                                params:{
                                    id:LC.Game.id,
                                    fields:{
                                        status:Game.STATUS.END_GAME,
                                        round:parseInt(LC.Game.round)-1
                                    }
                                }
                            })).catch(console.error);
                        }
                    }).catch(console.error);
                }

                execute();
            }else if(lifecycleStatus === Game.STATUS.END_GAME){
                setIsTimerOn(false);
                setAnimation('');

                const execute = () => {
                    setTimeout(() => {
                        dispatch(game.endGame({
                            params:{
                                gameid:LC.Game.id,
                                players:LC.getPlayers('all'),
                                round:LC.Game.round,
                                gameScores:LC.Game.gameScores
                            }
                        })).catch(console.error);
                    },Game.TIMER_TO_LOBBY*1000);
                }

                execute();
            }else if(lifecycleStatus === Game.STATUS.NONE){
                history.replace('/lobby');
            }
        }
    },[lifecycleStatus, dataGameRoundScores, dataGameRawRoundScores]);

    //popup leaderboard
    useAbortableEffect((status) => {
        if(leaderboard && Object.keys(leaderboard).length){
            if(!status.aborted){
                dispatch(setModalOpened('leaderBoard'));
            }
        }
    },[leaderboard]);

    //popup result
    useAbortableEffect((status) => {
        if(result && Object.keys(result).length){
            if(!status.aborted){
                dispatch(setModalOpened('gameResult'));
            }
        }
    },[result]);

    /*helpers */
    const changeUIState = (state = 'disable') => {
        if(state === 'disable'){
            setMoveBtnDisabled(true);
            setShowBtnDisabled(true);
            setPlayerCanPick(false);
            setChainable(false);
            setDraggable(false);
        }else if(state === 'enable'){
            setMoveBtnDisabled(true);
            setShowBtnDisabled(false);
            setPlayerCanPick(false);
            setChainable(false);
            setDraggable(true);
        }else if(state === Game.STATUS.DROPPED_NOT_SAME){
            setShowBtnDisabled(true);
            setMoveBtnDisabled(true);
            setPlayerCanPick(true);
            setChainable(false);
            setDraggable(true);
        }else if(state === Game.STATUS.DROPPED_SAME){
            setShowBtnDisabled(true);
            setMoveBtnDisabled(false);
            setPlayerCanPick(true);
            setChainable(false);
            setDraggable(true);
        }else if(state === Game.STATUS.DROPPED_CHAINABLE){
            setShowBtnDisabled(true);
            setMoveBtnDisabled(true);
            setPlayerCanPick(true);
            setChainable(true);
            setDraggable(true);
        }else if(
          state === Game.STATUS.PICKED
          || state === Game.STATUS.PICK_FROM_OPEN_DECK
        ){
            setMoveBtnDisabled(false);
            setShowBtnDisabled(true);
            setPlayerCanPick(false);
            setChainable(false);
            setDraggable(false);
        }
    }

    const offlineDetector = props => {
        const {seconds,onTimeout} = props;
        setScheduleTimeout(setTimeout(() => {
            onTimeout && onTimeout();
        },seconds*1000));
    }
    
    const changeStatusGame = async (status,props = {}) => {
        if (!LC.isTurn()) return;
        const {onSuccess} = props;
        changeUIState('disable');

        await dispatch(game.update({
            params: {
                id: LC.Game.id,
                fields: {
                    status: status
                }
            }
        }));
        onSuccess && onSuccess();
    }

    /*events*/
    const onDragEnd = (result) => {
        const {source,destination,draggableId} = result;
        if(!destination){
            return;
        }

        if(destination.droppableId === source.droppableId){
            return;
        }

        if(destination.droppableId === DA_OPEN_STACK){
            if(
                LC.Deck 
                && LC.Deck.current 
                && LC.Deck.current.length 
                && !Game.isSameCard(
                    LC.Deck.current,
                    Game.pickCardByIndex(
                        LC.getHolding(),
                        source.index,
                        false
                    )
                )
            ){
                return;
            }

            const result = LC.moveFromHost2Table(source.index);
            setCacheDeck(result.changes.fields);
            dispatch(deck.update({
                params:result.changes
            })).then(() => {
                console.log('da-table', result.status)
                changeStatusGame(result.status,{onSuccess: () => {
                    changeUIState(result.status)
                }});
            }).catch(console.error);
        }

        else if(destination.droppableId === DA_CURRENT_PLAYER){
            const insertToIndex = destination.index;
            let result;
            if(source.droppableId === DA_OPEN_STACK){
                result = LC.pickFromTable(insertToIndex,draggableId);

            }else if(source.droppableId === DA_CLOSED_STACK){
                result = LC.pickFromTable(insertToIndex,'closed');
            }
            
            setCacheDeck(result.changes.fields);
            dispatch(deck.update({
                params:result.changes
            })).then(() => {
                console.log('da-host', result.status)
                changeStatusGame(result.status,{onSuccess:() => {
                    changeUIState(result.status)
                }});
            }).catch(console.error);
        }
    }

    const onShow = () => {
        changeStatusGame(Game.STATUS.SHOW);
    };

    const onMove = () => {
        changeStatusGame(Game.STATUS.BEFORE_COMMIT_MOVE);
    };

    const onTimerEnd = () => {
        changeStatusGame(Game.STATUS.TIMEOUT);
    }

    const onClickLeaderboard = () => {
        setLeaderboard(LC.getLeaderBoard());
    }

    const onClickSetting = () => {
        console.log('onClickSetting');
    }

    const onNextRound = () => {    
    }

    const onSpectate = () => {
        dispatch(game.changeToSpectator({
            params:{
              gameid:LC.Game.id,
              userid:LC.User.id
            }
        })).then(() => {
            dispatch(game.update({
                params:{
                  id:LC.Game.id,
                  fields:{
                      currentTurn:LC.nextTurn(true)
                  }
                }
            })).catch(console.error);
        }).catch(console.error);
    }
    
    const onMainMenu = () => {
        history.replace('/');
    }
    
    if(!dataLoaded || 
        lifecycleStatus === null || 
        lifecycleStatus === Game.STATUS.NONE || 
        !(cacheDeck && cacheDeck.holding)){
        return (<Loader loading={true}/>);
    }
    
    const totalHolding = Object.keys(cacheDeck.holding).reduce((acc, user) => {
        const holding = Object.keys(cacheDeck.holding[user]).length;
        return {
            ...acc,
            [user]: holding,
        }
    }, {});

    return (
        <div style={{ 
            position: 'relative', 
            height: `${windowHeight}px`,
            userSelect: 'none',
        }}>
            <Holding score={LC ? LC.getScore({ raw: true }) : 0}/>
            <FlexContainer flexDirection="row" justifyContent="space-between">
                <GameSidebarLeft 
                  highlight={LC ? LC.isTurn() : false} 
                  players={LC ? LC.getPlayers() : []} 
                  hostName={LC ? LC.getName() : ''}
                  cardAnimation={cardAnimation}
                  totalHolding={totalHolding}
                  roomCode={dataGame.roomCode}
                />
                <FlexContainer flex={2}>
                    <Main
                      totalClosed={LC ? LC.getTotalClosedCards() : 0}
                      timerDuration={Game.TIMER_TURN}
                      timerType={'turn'}
                      timerId={LC ? LC.Game.id : ''}
                      isTimerOn={isTimerOn && (LC ? LC.isTimerOn():false)}
                      onTimerEnd={onTimerEnd}
                      players={LC ? LC.getPlayers() : []}
                      animation={animation}
                      cardAnimation={cardAnimation}
                      message={LC ? LC.getInfoMessage() : ''}
                      isDraggable={isDraggable}
                      isChainable={isChainable}
                      playerCanPick={playerCanPick}
                      onDragEnd={onDragEnd}
                      currentCard={LC ? Game.map2Json(cacheDeck.current) : []}
                      currentPlayerCard={LC ? Game.map2Json(cacheDeck.holding[dataUser.id]) : []}
                      currentTurn={LC ? LC.isTurn() : false}
                      openCard={LC ? Game.map2Json(cacheDeck.opened) : []}
                      wildCard={LC ? Game.map2Json(cacheDeck.wildcard) : ''}
                      totalHolding={totalHolding}
                      prevLCStatus={prevLCStatus}
                    />
                </FlexContainer>
                <GameSidebarRight 
                  onClickLeaderboard={onClickLeaderboard} 
                  onClickSetting={onClickSetting} 
                  players={LC ? LC.getPlayers() : []} 
                  onShow={onShow} 
                  onMove={onMove} 
                  moveDisabled={moveBtnDisabled} 
                  showDisabled={showBtnDisabled}
                  cardAnimation={cardAnimation}
                  totalHolding={totalHolding}
                />
            </FlexContainer>
            <ModalComponent 
              id="game"
              onSpectate={onSpectate} 
              onMainMenu={onMainMenu} 
              onNextRound={onNextRound} 
              result={result} 
              leaderboard={leaderboard}
            />
        </div>
    );
}
