import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyAzTOZCSM3VQJlEdZUDXYMLckXryJkDeuU",
    authDomain: "leastcount-web.firebaseapp.com",
    databaseURL: "https://leastcount-web.firebaseio.com",
    projectId: "leastcount-web",
    storageBucket: "leastcount-web.appspot.com",
    messagingSenderId: "431043877089",
    appId: "1:431043877089:web:9b02c53030d161d5173fa0",
    measurementId: "G-FF414ZWFHX"
};

firebase.initializeApp(config);
firebase.firestore();

export default firebase;
