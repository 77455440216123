import React from 'react';
import styled from 'styled-components';
import {getThemeColor} from '../../themes';

const Input = styled.input.attrs(props => ({
    type: props.type,
    name: props.name,
    value:props.value,
    onChange:props.onChange
  })).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['backgroundColor','borderRadius'].includes(prop)
  })`
    display:${props => props.display};
    border-radius:${props => props.borderRadius};
    background-color:${props => props.backgroundColor ? getThemeColor(props.backgroundColor,props.theme) : getThemeColor(props.theme.input.backgroundColor,props.theme)};
    color:${props => props.color ? getThemeColor(props.color,props.theme) : getThemeColor(props.theme.input.color,props.theme)};
    font-weight:${props=>props.fontWeight};
    font-size:${props=>props.fontSize};
    width:${props=>props.width};
    border:${props=>props.border};
    padding:${props=>props.padding};
`;

export default (_props)=>{
    /*eslint-disable no-undef,no-restricted-globals*/
    const props = {
        display = 'block',
        borderRadius = '0.5em',
        backgroundColor = '',
        color = '',
        fontWeight = 'bold',
        fontSize = '1em',
        width = '100%',
        border = 'none',
        padding = '0.75em',
        type = 'text',
        name = '',
        value = '',
        onChange = ''
    } = _props;
    /*eslint-enable no-undef,no-restricted-globals*/

    return (
        <Input {...props}/>
    )
}