import React, { useEffect } from 'react';
import styled from 'styled-components';
import { use100vh } from 'react-div-100vh';
import { useWindowSize } from '../../../src/hooks/windowSize';

const Div = styled.div`
    ${props => props.position ? `position: ${props.position};` : ''}
    ${props => props.minWidth ? `min-width: ${props.minWidth};` : ''}
    display:flex;
    max-height:100vh;
    flex-direction:${props=>props.flexDirection};
    justify-content:${props=>props.justifyContent};
    align-items:${props=>props.alignItems};
    flex:${props=>props.flex};
    width:${props => props.width};
    height:${props => props.height+props.unit};
    padding:${props => props.padding};
    margin:${props=>props.margin};
    align-self:${props=>props.alignSelf};
`;

export default (_props) => {
    const { height: windowHeight } = useWindowSize();
    /*eslint-disable no-undef*/
    const props = {
        children,
        unit = 'px',
        flexDirection = 'column',
        justifyContent = 'center',
        alignItems = 'center',
        width = 'auto',
        height,
        flex = 1,
        padding = 0,
        margin = 0,
        alignSelf = 'auto',
        minWidth = 0
    } = _props;
    /*eslint-enable no-undef*/

    if(typeof props.height === 'string'){
        props.unit = "";
    } else {
        props.height = use100vh();
    }

    return (
        <Div {...props}>
            {props.children}
        </Div>
    )
}
