import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider as ThemeProviderSC } from 'styled-components';
import { useAbortableEffect, useLocale } from '../hooks';
import { app } from '../actions';
import LIGHT from './light.js';
import DARK from './dark.js';
import Select from '../components/Select';
import Field from '../components/Field';

const availableOptions = {
  light: LIGHT,
  dark: DARK
};

export const isThemeColor = (color)=>{
  return ['primary','secondary','tertiary'].includes(color);
}

export const getThemeColor = (color,theme)=>{
  if(isThemeColor(color)){
    return theme[color];
  }
  return color;
}

export const getTheme = optionKey => key => {
  if(key === undefined){
    return availableOptions[optionKey];
  }
  return availableOptions[optionKey][key] || key;
};

export const ThemeComponent = (props) => {
    const dispatch = useDispatch();
    const current = useSelector(state=>state.app.theme);
    const locale = useLocale();
    const onChange = e => dispatch(app.setTheme(e.target.value));
    
    return (
      <Field label={locale.settings.selectTheme}>
        <Select 
          name="theme" 
          value={current} 
          onChange={onChange} 
          options={availableOptions}
        />
      </Field>
    );
};

export const ThemeProvider = ({children}) => {
  const current = useSelector(state=>state.app.theme);
  
  useAbortableEffect((status) => {
    if(!status.aborted){
      const theme = getTheme(current)('body');
      document.body.style.backgroundColor = theme.backgroundColor;
      document.body.style.color = theme.color;
    }
  },[current]);
  
  return (
    <ThemeProviderSC theme={getTheme(current)()}>
      {children}
    </ThemeProviderSC>
  )
}
