import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ReactComponent as CrownSvg } from './assets/crown.svg';
import { ReactComponent as GearSvg } from './assets/gear.svg';
import FlexContainer from '../FlexContainer';
import { getSizeCss } from '../../utilities/view';
import { getThemeColor } from '../../themes';
import { setModalOpened } from '../../actions/app';

const GlobalSetting = styled.div`
    position: ${props => props.global === 'true' ? 'absolute' : 'relative' };
    top: ${props => props.global === 'true' ? '20px' : 'auto' };
    right: ${props => props.global === 'true' ? '20px' : 'auto' };
    margin-top: 2em;
`;

const Div = styled.div.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => ![
        'borderRadius',
        'backgroundColor'
    ].includes(prop)
  })`
    border-radius:2px;
    border: 2px solid ${props => props.color ? getThemeColor(props.color,props.theme) : getThemeColor(props.theme.gear.color,props.theme)};
    cursor:pointer;
    padding:0.3em 0.6em;
    margin:0 0.25em;
`;

const GearIcon = styled(GearSvg).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => ![
        'borderRadius',
        'backgroundColor'
    ].includes(prop)
  })`
    display:${props => props.display};
    background-color:${props => props.backgroundColor ? getThemeColor(props.backgroundColor,props.theme) : getThemeColor(props.theme.gear.backgroundColor,props.theme)};
    & path{
        fill:${props => props.fill ? getThemeColor(props.fill,props.theme) : getThemeColor(props.theme.gear.fill,props.theme)};
    }
    color:${props => props.color ? getThemeColor(props.color,props.theme) : getThemeColor(props.theme.gear.color,props.theme)};
    margin:${props=>props.margin};
    width:${props => props.width};
    height:${props => props.height};
`;

const CrownIcon = styled(CrownSvg).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => ![
        'borderRadius',
        'backgroundColor'
    ].includes(prop)
  })`
    display:${props => props.display};
    background-color:${props => props.backgroundColor ? getThemeColor(props.backgroundColor,props.theme) : getThemeColor(props.theme.gear.backgroundColor,props.theme)};
    & path{
        fill:${props => props.fill ? getThemeColor(props.fill,props.theme) : getThemeColor(props.theme.gear.fill,props.theme)};
    }
    color:${props => props.color ? getThemeColor(props.color,props.theme) : getThemeColor(props.theme.gear.color,props.theme)};
    margin:${props=>props.margin};
    width:${props => props.width};
    height:${props => props.height};
`;

export default (_props)=>{
    /*eslint-disable no-undef*/
    let props = {
        display = 'block',
        borderRadius = '1em',
        backgroundColor = '',
        fill = '',
        color = '',
        margin = 'auto',
        width = '1.5em',
        height = '1.5em',
        size = '',
        global = 'false',
    } = _props;
    /*eslint-enable no-undef*/
    const dispatch = useDispatch();

    if(props.size){
        props = {...props,...getSizeCss(props.size)};
    }
    
    const doSetting = () => {
        const { onClickSetting } = _props;
        if(onClickSetting && typeof onClickSetting === 'function') {
            onClickSetting();
        } else {
            dispatch(
                setModalOpened({
                    modalOpened: 'settings',
                    modalId: 'global',
                })
            );
        }
    }

    return (
        <GlobalSetting
          global={props.global}
        >
            <FlexContainer flexDirection="row" height="auto">
                {
                    props.global === 'false'
                      ? <Div {...props} onClick={_props.onClickLeaderboard}><CrownIcon {...props} alt="leaderboard"/></Div>
                      : null
                }
                <Div {...props} onClick={doSetting}><GearIcon {...props} alt="setting"/></Div>
            </FlexContainer>
        </GlobalSetting>
    )
}
