import React from 'react';
import styled from 'styled-components';
import { useLocale } from '../../../hooks';
import AppHeader from '../../../components/AppHeader';
import { LanguageComponent } from '../../../locales';
import { ThemeComponent } from '../../../themes';

const SettingsContainer = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: ${props => props.theme.modalBodyBackground};
  color: ${props => props.theme.modalBodyColor};
  border-radius: 5px;
  font-size: 1.2em;
  text-align:center;
  padding: 20px;
`;

const FieldContainer = styled.div`
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const Settings = () => {
    const locale = useLocale();
    
    return (
        <SettingsContainer>
            <AppHeader subtitle={locale.settings.gameSettings}/>
            <FieldContainer>
              <LanguageComponent />
            </FieldContainer>
            <FieldContainer>
              <ThemeComponent />
            </FieldContainer>
        </SettingsContainer>
    )
}

export default Settings;
