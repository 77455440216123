import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import { useToasts } from 'react-toast-notifications';
import { useLocale, useIsFsRequesting, useAbortableEffect } from '../../hooks';
import { useUsers, useGames, useGames_Users } from '../../hooks/collection';
import { game, user } from '../../actions';
import { Game } from '../../utilities';
import FlexContainer from '../../components/FlexContainer';
import Loader from '../../components/Loader';
import { ModalComponent } from '../../modals';
import LobbySidebar from './LobbySidebar';
import LobbyMain from './LobbyMain';
import Setting from '../../components/Setting';

export default (props) => {
    const { history, match } = props;
    const dispatch = useDispatch();
    const locale = useLocale();
    const loading = useIsFsRequesting();
    const { addToast } = useToasts();
    const [ dataSidebar, setDataSidebar ] = useState([]);
    const [ areYouHost, setAreYouHost ] = useState(false);
    const [ isRandom, setRandom ] = useState(false);

    const {
        data: dataUser,
    } = useUsers(user.get(),{
        onLoaded: resp => {
            if(resp.empty){
                history.replace('/create');
            }else if(!resp.data.currentGame){
                if(match.params.id){
                    history.replace(`/join/${match.params.id}`);
                }else{
                    history.replace('/create');
                }
            }else{
                if(match.params.id){
                    if(match.params.id !== resp.data.currentGame){
                        history.replace(`/join/${match.params.id}`);
                    }
                }

                dispatch(game.isExists({
                    params:{
                        id: resp.data.currentGame
                    }
                })).then(exists=>{
                    if(!exists){
                        if(resp.data.currentGame){
                            dispatch(user.update({
                                params:{
                                    id: resp.data.id,
                                    fields:{
                                        currentGame:''
                                    }
                                }
                            })).then(() => {
                                history.replace('/create');
                            }).catch(console.error);
                        }else{
                            alert(`${locale.join.room} '${resp.data.currentGame}' ${locale.create.notExists}`);
                            history.replace('/');
                        }
                    }
                }).catch(console.error);
            }
        }
    });
    
    const {
        data: dataGame,
    } = useGames(match.params.id ? match.params.id : dataUser ? dataUser.currentGame : 0,{
        onLoaded: resp => {
            if(!resp.empty){
                if(resp.data.status !== ''){
                    history.replace('/game');
                }
            }
        }
    });

    const {
        data: dataGameUsers,
    } = useGames_Users(match.params.id ? match.params.id : dataUser ? dataUser.currentGame : 0);
    
    useAbortableEffect((status) => {
        if(!status.aborted){
            setDataSidebar(Game.getLobbySidebar(dataGame,dataGameUsers));
        }
    },[dataGame,dataGameUsers]);


    useAbortableEffect((status) => {
        if(!status.aborted){
            setAreYouHost(Game.areYouHost(dataGameUsers,dataUser && dataUser.id));
        }
    },[dataUser,dataGameUsers]);

    //event
    const onStart = () => {
        if(isRandom){
            dispatch(game.randomMatch({
                params: {
                    userid: dataUser.id,
                    gameid: dataUser.currentGame
                }
            })).then(() => {
                setRandom(false);
            }).catch((err) => {
                alert(err.message);
                setRandom(false);
            })
        }else{
            if(dataSidebar.players && 
                dataSidebar.players.length <= 8 && 
                dataSidebar.players.length >= 2
                ){
                if(dataGame.status === ''){
                    dispatch(game.update(
                        {
                            params: {
                                id: dataUser.currentGame,
                                fields: {
                                    round: 0,
                                    status: Game.STATUS.INITIAL,
                                    currentTurn: dataUser.id
                                }
                            }
                        }
                    ));
                }
            }else{
                alert(locale.deck.reqMinimum);
            }
        }
    }

    const onLeave = () => {
        dispatch(user.changeRoom({
            params:{
                oldgameid: dataUser.currentGame,
                gameid:"",
                userid: dataUser.id
            }
        }));
    }

    const onRandom = (e) => {
        setRandom(e);
    }

    const onCopyLink = () => {
        copy(`${window.location.origin}/join/${dataSidebar.roomID}`);
        addToast('Link copied', {
            appearance: 'info',
            autoDismiss: true,
        });
    }

    const onRemovePlayer = (userid) => {
        dispatch(game.removePlayer({
            params:{
                userid: userid,
                gameid: dataUser.currentGame
            }
        })).catch(err=>{
            alert(err.message);
        });
    }

    return (
        <Loader loading={loading}>
            <Setting 
              global="true"
            />
            <FlexContainer flexDirection="row">
                <LobbySidebar 
                  isHost={areYouHost} 
                  onRemovePlayer={onRemovePlayer} 
                  onCopyLink={onCopyLink} 
                  onRandom={onRandom} 
                  data={dataSidebar}
                />
                <LobbyMain 
                  isHost={areYouHost} 
                  onStart={onStart} 
                  onLeave={onLeave} 
                  currentHostName={Game.getHostName(dataGameUsers)}
                />
            </FlexContainer>
            <ModalComponent 
              id="lobby"
              onLeave={onLeave}
            />
        </Loader>
    );
}
