import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocale } from '../hooks';
import FlexContainer from '../components/FlexContainer';
import AppHeader from '../components/AppHeader';
import Container from '../components/Container';
import Button from '../components/Button';

export default ()=>{
    const history = useHistory();
    const locale = useLocale();

    return (
        <FlexContainer height={80}>
            <AppHeader title={locale.app.title} subtitle={locale.app.subtitle}/>
            <Container maxWidth="360px" minWidth="360px">
                <AppHeader title={{content:locale.landing.welcome,fontSize:'1.5em'}}/>
                <FlexContainer flexDirection="row" height="auto" padding="1.5rem 2rem">
                    <Button onClick={() => history.push('/create')}>{locale.landing.create}</Button>
                    <Button backgroundColor={"secondary"} onClick={() => history.push('/join')}>{locale.landing.join}</Button>
                </FlexContainer>
            </Container>
        </FlexContainer>
    );
}