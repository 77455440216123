import React from 'react';
import styled from 'styled-components';
import Action from '../Action';
import { useLocale } from '../../../hooks';
import AppHeader from '../../../components/AppHeader';

const ForfeitContainer = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: ${props => props.theme.modalBodyBackgroundSecondary};
  color: ${props => props.theme.modalBodyColorSecondary};
  border-radius: 5px;
  font-size: 1.2em;
  text-align:center;
  padding: 20px;
`;

const Forfeit = ({ onSpectate, onMainMenu })=>{
    const locale = useLocale('game');
    
    return (
        <ForfeitContainer>
            <AppHeader subtitle={locale.resultAction.forfeited} />
            <Action 
              onSpectate={onSpectate} 
              onMainMenu={onMainMenu}
              isForfeited={true}
            />
        </ForfeitContainer>
    )
}

export default Forfeit;
