import React from 'react';
import StackCard from './StackCard';
import Avatar from '../../../../components/Avatar';

export default (_props) => {
    /*eslint-disable no-undef*/
    let props = {
        size = 'medium',
        total = 7,
        distance = '',
        label = '',
        styleGrid = {},
        flip = false,
        user = {},
        cardAnimation = 'none',
        cardAnimationType = 'opponentTopCenter',
        totalHolding = {},
    } = _props;
    /*eslint-enable no-undef*/

    const userHolding = props.totalHolding[_props.user.id];
    let tmpLabel = props.label;
    delete props.label;

    let highlight = false;
    if(_props.user){
        tmpLabel = _props.user.name;
        highlight = _props.user._turn;
    }
    if(userHolding >= 0) {
      props.total = userHolding;
    }
    
    if(props.flip){
        return (
            <div style={{display:'grid',gridTemplateColumns: 'auto auto',...props.styleGrid}}>
                <div style={{margin:'auto 0.5em',textAlign:'right'}}>
                    <label style={{fontWeight:'normal',fontSize:'0.8em'}}>{tmpLabel}</label>
                </div>
                <div>
                    <Avatar {...props} highlight={highlight}/>
                </div>
                <div>
                    <StackCard {...props}/>
                </div>
                <div />
            </div>
        )
    }

    return (
        <div style={{display:'grid',gridTemplateColumns: 'auto auto',...props.styleGrid}}>
            <div style={{justifySelf:'end'}}>
                <Avatar {...props} highlight={highlight}/>
            </div>
            <div style={{margin:'auto 0.5em'}}>
                <label style={{fontWeight:'normal',fontSize:'0.8em'}}>{tmpLabel}</label>
            </div>
            <div />
            <div id="game-section-main-top">
                <StackCard {...props}/>
            </div>
        </div>
    )
}
