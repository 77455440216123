import {setFailure,setSuccess,setRequest} from './app';
import * as game from './game';

export const set = (item)=>{
    localStorage.setItem("userid",item);
}

export const get = ()=>{
    const id = localStorage.getItem("userid");
    if(id){
        return id;
    }
    
    return false;
}

export const create = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
    indicator && dispatch(setRequest());
    const db = getFirestore();
    try{
        if(params.name && params.name.length > 30){
            throw new Error("Maximum character name is 30");
        }

        const docRef = db.collection("users").doc();
        await docRef.set(params);
        set(docRef.id);
        dispatch(setSuccess(docRef.id));

        return docRef.id;
    }catch(err){
        dispatch(setFailure(err.message));
        throw err;
    }
} 

export const update = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
    indicator && dispatch(setRequest());
    const db = getFirestore();
    try{
        const {id,fields} = params;
        const docRef = db.collection("users").doc(id);        
        await docRef.update(fields);

        dispatch(setSuccess());
    }catch(err){
        dispatch(setFailure(err.message));
        throw err;
    }
}

export const exitRoom = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
    indicator && dispatch(setRequest());
    const db = getFirestore();
    try{
        const {gameid,userid} = params;
        const colRefGame = db.collection("games");
        const docRefGame = colRefGame.doc(gameid);
        const colRefGame_Users = docRefGame.collection("users");
        const docRefGame_User = colRefGame_Users.doc(userid);
        let dataRefGame_User = await docRefGame_User.get();
        dataRefGame_User = await dataRefGame_User.data();

        await docRefGame_User.delete();
        const docRefGame_Users = await colRefGame_Users.get();
        if(docRefGame_Users.docs.length === 0){
            
            const rs = await docRefGame.collection('roundScores').get();
            const rrs = await docRefGame.collection('rawRoundScores').get();
            rs.forEach(element => {
                element.ref.delete();
            });
            rrs.forEach(element => {
                element.ref.delete();
            });

            await docRefGame.delete();
            await db.collection("decks").doc(gameid).delete();
        }else{
            let isHost = dataRefGame_User ? dataRefGame_User.host : false;
            if(isHost){
                await docRefGame_Users.docs[0].ref.update({
                    host:true
                });
            }
        }

        dispatch(setSuccess());
    }catch(err){
        dispatch(setFailure(err.message));
        throw err;
    }
}

export const changeRoom = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
    indicator && dispatch(setRequest());
    const db = getFirestore();
    try{
        const {oldgameid,gameid,userid} = params;

        await dispatch(update({
            params:{
                id:userid,
                fields:{
                    currentGame:gameid
                }
            }
        }));
        
        if(oldgameid){
            await dispatch(exitRoom({
                params:{
                    userid:userid,
                    gameid:oldgameid
                }
            }));
        }

        dispatch(setSuccess());
    }catch(err){
        dispatch(setFailure(err.message));
        throw err;
    }
}

export const joinRoom = ({params,indicator = true})=>async(dispatch)=>{
    indicator && dispatch(setRequest());
    try{
        const {oldgameid,roomId,user,username,theme,language} = params;
        let userid = 0;
        
        if(!roomId){
            throw new Error(`Please provide Room ID`);
        }

        const currentGame = await dispatch(game.isExists({
            params:{
                id:roomId
            }
        }));

        const gameid = (currentGame || {}).id;
        if(!currentGame.id){
            throw new Error(`Room ID '${roomId}' not found`);
        }

        const isFull = await dispatch(game.isFull({
          params:{
            gameid:gameid
          }
        }));

        if(user){
            if(isFull){
                throw new Error(`The room ${gameid} is full`);
            }

            await dispatch(changeRoom({
                params:{
                    userid:user.id,
                    gameid:gameid,
                    oldgameid:oldgameid
                }
            }));
        }else{
            userid = await dispatch(create({
                params:{
                    name:username,
                    currentGame:gameid,
                    theme:theme,
                    language:language
                }
            }));
        }

        await dispatch(game.addNewPlayer({
            params:{
                username:user ? user.name : username,
                userid:user ? user.id : userid,
                gameid:gameid
            }
        }))

        dispatch(setSuccess());
    }catch(err){
        dispatch(setFailure(err.message));
        throw err;
    }
}

export const createRoom = ({params,indicator = true})=>async(dispatch,getState,{getFirestore})=>{
    indicator && dispatch(setRequest());
    try{
        const {user,username,theme,language} = params;
        let userid = 0;

        if(user){
            userid = user.id;
        }else{
            userid = await dispatch(create({
                params:{
                    name:username.trim(),
                    currentGame:"",
                    theme:theme,
                    language:language
                }
            }));
        }

        const gameid = await dispatch(game.create({
            params:{
                userid:userid,
                username:username.trim()
            }
        }));

        await dispatch(update({
            params:{
                id:userid,
                fields:{
                    name:username.trim(),
                    currentGame:gameid
                }
            }
        }))

        dispatch(setSuccess());
    }catch(err){
        dispatch(setFailure(err.message));
        throw err;
    }
}
